import { Checkbox, Modal, Tooltip } from "antd";
import styles from "./styles.module.scss";
import "../modal.scss";
import {
  WIDGET_METRIC_GROUPS,
  WIDGET_METRICS_CONFIG,
} from "@services/math/math.const";
import classNames from "classnames";
import { WidgetMetricKey } from "@services/math/math.service";
import info from "@assets/icons/i.svg";
import { defaultWidgets } from "@pages/Dashboard/store/dashboard.state";

interface SettingsModalProps {
  open: boolean;
  choosedWidgets: WidgetMetricKey[];
  setChoosedWidgets: (widgets: WidgetMetricKey[]) => void;
  maxWidgets?: number;
  onApply: () => void;
  onClose: () => void;
  allowedGroups?: string[];
  isCRO: boolean;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({
  open,
  onClose,
  onApply,
  maxWidgets = 10,
  allowedGroups,
  isCRO,
  choosedWidgets,
  setChoosedWidgets,
}) => {
  const selectWidget = (key: WidgetMetricKey) => {
    if (choosedWidgets.includes(key)) {
      setChoosedWidgets(choosedWidgets.filter((widget) => widget !== key));
    } else {
      if (choosedWidgets.length >= maxWidgets) {
        return;
      }
      setChoosedWidgets([...choosedWidgets, key]);
    }
  };

  const handleApply = () => {
    onApply();
  };

  const groupsToShow = allowedGroups
    ? WIDGET_METRIC_GROUPS.filter((group) =>
        allowedGroups.includes(group.title),
      )
    : WIDGET_METRIC_GROUPS.filter((group) => group.title !== "Sales Config");

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      className={classNames("modal", { cro: isCRO })}
    >
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Choose Metrics</h2>
          <span>
            Choose which metrics you want to show in the «Sales Performance»
          </span>
        </div>
        <div
          className={classNames(styles.keys, {
            [styles.keys__disabled]: choosedWidgets.length >= maxWidgets,
          })}
        >
          {groupsToShow.map((group) => (
            <div className={styles.keys__group} key={group.title}>
              <h3>{group.title}</h3>
              {group.keys.map((key) => (
                <div className={styles.key} key={key}>
                  <Checkbox
                    onChange={() => selectWidget(key)}
                    checked={choosedWidgets.includes(key)}
                  />
                  <span>{WIDGET_METRICS_CONFIG[key]?.label || key}</span>

                  <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info || ""}>
                    <img src={info} alt="info" />
                  </Tooltip>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <button onClick={() => setChoosedWidgets(defaultWidgets)}>
            reset to default
          </button>
          <button onClick={handleApply}>Apply</button>
        </div>
      </div>
    </Modal>
  );
};
