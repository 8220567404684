import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CanvaElement, useCanvaStore } from "src/store/canva/canva.state";
import { v4 as uuid } from "uuid";
import { Input } from "@components/Input";
import Search from "@assets/icons/search";
import { Element, ElementsAccordion } from "./ElementsAccordion";
import {
  campaignElements,
  croElements,
  organicElements,
  financeElements,
  getWidgetSize,
  marketElements,
  reportsElements,
} from "./utils";
import ChevronDown from "@assets/icons/chevron-down";
import classNames from "classnames";
import { ELEMENT_ID } from "src/store/canva/element-id";
import { useBrandStore } from "src/store/brand";

const setActiveElement = (
  elementsList: Element[],
  canvaElements: CanvaElement[],
) => {
  const activeElements = canvaElements.filter((el) => el.active);
  return elementsList.map((mockElement) => {
    const activeElement = activeElements.find(
      (el) => el.elementId === mockElement.elementId,
    );
    return {
      ...mockElement,
      active: !!activeElement,
    };
  });
};

export const Elements: React.FC = () => {
  const { elements, addElement, removeElement } = useCanvaStore();
  const [search, setSearch] = useState("");
  const [openedAccordion, setOpenedAccordion] = useState({
    revenue: false,
    finance: false,
    supplyChain: false,
    marketIntelegence: false,
    reportsIntelegence: false,
  });
  const [elementsByCategory, setElementsByCategory] = useState<
    Record<string, Element[]>
  >({
    campaign: setActiveElement(campaignElements, elements),
    cro: setActiveElement(croElements, elements),
    organic: setActiveElement(organicElements, elements),
    finance: setActiveElement(financeElements, elements),
    marketIntelegence: setActiveElement(marketElements, elements),
    reportsIntelegence: setActiveElement(reportsElements, elements),
  });
  const { brands } = useBrandStore();
  const [widgetSize, setWidgetSize] = useState({ width: 4, height: 90 });

  const openAccordion = (category: string) => {
    setOpenedAccordion((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  useEffect(() => {
    setElementsByCategory({
      campaign: setActiveElement(campaignElements, elements),
      cro: setActiveElement(croElements, elements),
      organic: setActiveElement(organicElements, elements),
      finance: setActiveElement(financeElements, elements),
      marketIntelegence: setActiveElement(marketElements, elements),
      reportsIntelegence: setActiveElement(reportsElements, elements),
    });
  }, [elements]);

  const handleToggleElement = (elementId: ELEMENT_ID, category: string) => {
    setElementsByCategory((prevState) => {
      const categoryElements = prevState[category] || [];

      const updatedCategoryElements = categoryElements.map((el) => {
        if (el.elementId === elementId) {
          const updatedElement = { ...el, active: !el.active };

          if (updatedElement.active) {
            const newCanvaElement: CanvaElement = {
              id: uuid(),
              elementId: elementId,
              x: 1,
              y: 50,
              size: getWidgetSize(
                elementId,
                elementId === ELEMENT_ID.BRAND ? brands.length : 1,
              ),
              active: true,
            };

            addElement(newCanvaElement);

            setTimeout(() => {
              const newElement = document.getElementById(
                `grid-item-${newCanvaElement.id}`,
              );
              if (newElement) {
                newElement.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                });
              }
            }, 100);
          } else {
            const elementToRemove = elements.find(
              (el) => el.elementId === elementId,
            );
            if (elementToRemove) {
              removeElement(elementToRemove.id);
            }
          }

          return updatedElement;
        }
        return el;
      });

      return {
        ...prevState,
        [category]: updatedCategoryElements,
      };
    });
  };
  useEffect(() => {
    const newWidgetSize = getWidgetSize(ELEMENT_ID.BRAND, brands.length);
    setWidgetSize(newWidgetSize);
  }, [brands]);

  const financeActiveElements = elementsByCategory.finance.filter(
    (el) => el.active,
  ).length;

  const customReportsActiveElements =
    elementsByCategory.reportsIntelegence.filter((el) => el.active).length;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Add elements</h3>
        <Input
          placeholder="Search"
          value={search}
          onChange={setSearch}
          icon={<Search />}
        />
      </div>
      {search ? (
        <ul className={styles.elementsListSearch}>
          {Object.keys(elementsByCategory).map((category) =>
            elementsByCategory[category]
              .filter((element, index) =>
                element.name.toLowerCase().includes(search.toLowerCase()),
              )
              .map((element, index) => (
                <li key={index}>
                  <button
                    className={styles.element}
                    onClick={() =>
                      handleToggleElement(element.elementId, category)
                    }
                  >
                    <img src={element.icon} alt={element.name} />
                    {element.name}
                    <span
                      className={classNames(styles.status, {
                        [styles.active]: element.active,
                      })}
                    ></span>
                  </button>
                </li>
              )),
          )}
        </ul>
      ) : (
        <>
          <div
            className={classNames(styles.accordion, {
              [styles.open]: openedAccordion.revenue,
            })}
          >
            <button
              onClick={() => {
                openAccordion("revenue");
              }}
            >
              Revenue (CMO)
              <ChevronDown />
            </button>
            <ul className={classNames(styles.list)}>
              <ElementsAccordion
                title="CRO"
                elements={elementsByCategory.cro}
                onElementClick={handleToggleElement}
                search={search}
                category={"cro"}
                variant={"small"}
              />
              <ElementsAccordion
                title="Campaign Manager"
                elements={elementsByCategory.campaign}
                onElementClick={handleToggleElement}
                search={search}
                category={"campaign"}
                variant={"small"}
              />
              <ElementsAccordion
                title="Organic"
                elements={elementsByCategory.organic}
                onElementClick={handleToggleElement}
                search={search}
                category={"organic"}
                variant={"small"}
              />
            </ul>
          </div>
          <div
            className={classNames(styles.accordion, {
              [styles.open]: openedAccordion.finance,
            })}
          >
            <button
              onClick={() => {
                openAccordion("finance");
              }}
            >
              Finance (CFO)
              {financeActiveElements > 0 && <div>{financeActiveElements}</div>}
              <ChevronDown />
            </button>
            <ul className={classNames(styles.list)}>
              <ElementsAccordion
                elements={elementsByCategory.finance}
                onElementClick={handleToggleElement}
                search={search}
                category={"finance"}
                variant={"small"}
              />
            </ul>
          </div>

          <div
            className={classNames(styles.accordion, {
              [styles.open]: openedAccordion.supplyChain,
            })}
          >
            <button
              onClick={() => {
                openAccordion("supplyChain");
              }}
            >
              Supply Chain (COO)
              <ChevronDown />
            </button>
            <ul className={classNames(styles.list)}>
              <ElementsAccordion
                elements={elementsByCategory.marketIntelegence}
                onElementClick={handleToggleElement}
                search={search}
                category={"supplyChain"}
                variant={"small"}
              />
            </ul>
          </div>
          <div
            className={classNames(styles.accordion, {
              [styles.open]: openedAccordion.marketIntelegence,
            })}
          >
            <button
              onClick={() => {
                openAccordion("marketIntelegence");
              }}
            >
              Market Intelligence
              <ChevronDown />
            </button>
            <ul className={classNames(styles.list)}>
              <ElementsAccordion
                elements={elementsByCategory.marketIntelegence}
                onElementClick={handleToggleElement}
                search={search}
                category={"marketIntelegence"}
                variant={"small"}
              />
            </ul>
          </div>

          <div
            className={classNames(styles.accordion, {
              [styles.open]: openedAccordion.reportsIntelegence,
            })}
          >
            <button
              onClick={() => {
                openAccordion("reportsIntelegence");
              }}
            >
              Custom Reports
              {customReportsActiveElements > 0 && (
                <div>{customReportsActiveElements}</div>
              )}
              <ChevronDown />
            </button>
            <ul className={classNames(styles.list)}>
              <ElementsAccordion
                elements={elementsByCategory.reportsIntelegence}
                onElementClick={handleToggleElement}
                search={search}
                category={"reportsIntelegence"}
                variant={"small"}
              />
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
