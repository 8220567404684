import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "@hooks/useTitle";
import { Widgets } from "@pages/Dashboard/features/Widgets";
import { Products } from "@pages/Dashboard/features/Products";
import { WelcomeSection } from "@components/WelcomeSection";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import { SalesChannel } from "./features/SalesChannel";
import { useMaintenance } from "src/store/maintenance.state";
import SalesPerformance from "@components/SalesPerformance";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("window.dashboard"));
  const { maintenance } = useMaintenance();
  return (
    <PageWrapper>
      <WelcomeSection />
      <Widgets />
      <SalesPerformance choosedGroups={"all"} />
      {maintenance && <SalesChannel />}
      <Products />
    </PageWrapper>
  );
};
