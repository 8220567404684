// Legend.tsx
import React from "react";
import "./index.css";
import { Checks } from "../types";
import { mapCheckKeyToMetricKey } from "@pages/Dashboard/mocks/mockMetricsData";
import Settings from "@assets/img/settings";
import classNames from "classnames";

interface LegendProps {
  handleOpenModal: () => void;
  metricsColors: { [key: string]: string };
  checks: Checks;
  allowedMetrics?: string[];
  buttonIcon?: boolean;
}

export const Legend: React.FC<LegendProps> = ({
  handleOpenModal,
  checks,
  metricsColors,
  allowedMetrics,
  buttonIcon,
}) => {
  return (
    <div
      className={classNames({
        "sales-performance__legend": !buttonIcon,
        "sales-performance__legendBtn": buttonIcon,
      })}
    >
      <div
        className={classNames({
          "sales-performance__legendGroup": !buttonIcon,
          performance__legendGroup: buttonIcon,
        })}
      >
        {Object.keys(checks).map((check, index) => {
          if (!checks[check]) return null;

          const metricLabel = mapCheckKeyToMetricKey(check);
          if (allowedMetrics && !allowedMetrics.includes(metricLabel)) {
            return null;
          }

          return (
            <div key={index + check} className="sales-performance__legendItem">
              <div
                className="sales-performance__legendCircle"
                style={{ backgroundColor: metricsColors[check] }}
              />
              <div className="sales-performance__legendLabel body-large bold">
                {metricLabel}
              </div>
            </div>
          );
        })}
      </div>
      <button onClick={handleOpenModal} aria-label="Open Metrics Settings">
        {buttonIcon && <Settings />}
      </button>

      {!buttonIcon && (
        <button
          className="sales-performance__button"
          onClick={handleOpenModal}
          aria-label="Metrics"
        >
          Metrics
        </button>
      )}
    </div>
  );
};
