import "./index.css";
import Star from "./Star.tsx";
import { FC } from "react";

interface IProps {
  rating?: number;
  showRating?: boolean;
  oneRating?: boolean;
}

export const Rating: FC<IProps> = ({
  rating,
  showRating = true,
  oneRating = false,
}: IProps) => {
  const computedWidth = `${(rating / 5) * 100}%`;

  return oneRating ? (
    <div className="ratingStarContainer">
      <div className="rating__stars">
        <div className="rating-underlay">
          <div className="rating__star">
            <Star />
          </div>
        </div>
        <div className="rating-overlay" style={{ width: computedWidth }}>
          <div className="rating__star">
            <Star />
          </div>
        </div>
      </div>
      {showRating && <p className="rating__value heading-3">{rating}</p>}
    </div>
  ) : (
    <div className="rating">
      <div className="rating__stars">
        <div className="rating-underlay">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="rating__star">
              <Star />
            </div>
          ))}
        </div>
        <div className="rating-overlay" style={{ width: computedWidth }}>
          {[...Array(5)].map((_, i) => (
            <div key={i} className="rating__star">
              <Star />
            </div>
          ))}
        </div>
      </div>
      {showRating && <p className="rating__value heading-3">{rating}</p>}
    </div>
  );
};
