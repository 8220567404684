import { Api } from "src/utils/api";
import { CANVAS_API_BASE_URL } from "./canvas.constant";

type PerformanceType = "All" | "Good" | "Mid" | "Poor" | "No Sales";

export interface IPerformanceTableData {
  performance: string;
  sales: number;
  spend: number;
  acos: number;
  cpc: number;
  key: number | string;
}

export interface IPerformanceTableDataCampaign extends IPerformanceTableData {
  campaign_name: string;
}

export interface ISaturationCurveData {
  x: number;
  y: number;
  color: string;
}

export class CanvasApi extends Api {
  constructor() {
    super(CANVAS_API_BASE_URL);
  }

  public getSalesPerformance = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSalesPerformanceCampaign = async (
    date_start: string,
    date_end: string,
    performance: PerformanceType,
    saturation?: number,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.get("/campaign", {
      date_start,
      date_end,
      performance,
      saturation,
    });
  };

  public getSalesPerformanceTarget = async (
    date_start: string,
    date_end: string,
    performance: PerformanceType,
    campaign_name: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/target", {
      date_start,
      date_end,
      performance,
      campaign_name,
      saturation,
    });
  };

  public getSalesPerformanceAdType = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/ad-type", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSalesPerformanceMatchType = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/match-type", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSaturationCurve = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<ISaturationCurveData[]> => {
    return await this.get("/saturation-curve", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSalesPerformanceAdTypeCampaign = async (
    date_start: string,
    date_end: string,
    ad_type: string,
    saturation?: number,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.get("/ad-type/campaign", {
      date_start,
      date_end,
      ad_type,
      saturation,
    });
  };

  public getSalesPerformanceAdTypeSearchTerms = async (
    date_start: string,
    date_end: string,
    ad_type: string,
    campaign_name: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/ad-type/search-terms", {
      date_start,
      date_end,
      ad_type,
      campaign_name,
      saturation,
    });
  };

  public getSalesPerformanceMatchTypeCampaign = async (
    date_start: string,
    date_end: string,
    match_type: string,
    saturation?: number,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.get("/match-type/campaign", {
      date_start,
      date_end,
      match_type,
      saturation,
    });
  };

  public getSalesPerformanceMatchTypeTarget = async (
    date_start: string,
    date_end: string,
    match_type: string,
    campaign_name: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/match-type/target", {
      date_start,
      date_end,
      campaign_name,
      match_type,
      saturation,
    });
  };

  public getSalesPerformanceTargets = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/targets", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSalesPerformanceTargetsCampaigns = async (
    date_start: string,
    date_end: string,
    target: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/targets/campaigns", {
      date_start,
      date_end,
      target,
      saturation,
    });
  };

  public getSalesPerformanceFunnel = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/funnels", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSalesPerformanceFunnelCampaign = async (
    date_start: string,
    date_end: string,
    funnel: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/funnels/campaigns", {
      date_start,
      date_end,
      funnel,
      saturation,
    });
  };

  public getSalesPerformanceFunnelSearchTerms = async (
    date_start: string,
    date_end: string,
    funnel: string,
    campaign_name: string,
    saturation?: number,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("/funnels/search-terms", {
      date_start,
      date_end,
      funnel,
      campaign_name,
      saturation,
    });
  };

  public getSalesPerformanceCampaigns = async (
    date_start: string,
    date_end: string,
    saturation?: number,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.get("/campaigns", {
      date_start,
      date_end,
      saturation,
    });
  };

  public getSalesPerformanceCampaignsSearchTerms = async (
    date_start: string,
    date_end: string,
    campaign_name: string,
    saturation?: number,
  ): Promise<IPerformanceTableDataCampaign[]> => {
    return await this.get("/campaigns/search-terms", {
      date_start,
      date_end,
      campaign_name,
      saturation,
    });
  };
}
