import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import Header from "./components/Header";
import ProductInfo from "./components/ProductInfo";
import TopProblems from "./components/TopProblems";
import Reviews from "./components/Reviews";
import ReviewScore from "./components/ReviewScore";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import AspectsAndPrases from "./components/AspectsAndPrases";
import { loadInsideProductData } from "src/utils/insides";
import { AmazonService } from "@services/amazon/amazon.service";
import { SpiderService } from "@services/spider/spider.services";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import styles from "./styles.module.scss";
import { ExecutiveSummary } from "@components/ExecutiveSummary";

export interface Item {
  asin?: string;
  title?: string;
  score?: number | null;
  number_in_BSR?: number;
  image?: string;
}

interface Category {
  Department: string;
  Category: string;
  Sub_category: string;
  Sub_category_1: string;
  Sub_category_2: string;
  Sub_category_3: string;
  items: Item[];
  levels: number;
}

type CategoriesResponse = Category[];

const formattedKeys = (spiderDots) =>
  Object.keys(spiderDots).map((key) =>
    key
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
  );

export type OverviewType = "my-product" | "show-room";

export const Overview: React.FC<{ type?: OverviewType }> = ({
  type = "my-product",
}) => {
  const [visibleReviews, setVisibleReviews] = useState<number>(10);
  const [score, setScore] = useState<number | null>(null);
  const [stats, setStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    productOne,
    setProductOneAspects,
    setProductOnePhrases,
    setProductOneProblems,
    setProductOneReviews,
    setProductOneSpiderTitles,
    setProductOneSpiderValues,
    clearProductOne,
    setProductOneHypotheses,
  } = useInsightsStore();

  const isCategoryArray = (data: any): data is CategoriesResponse => {
    return Array.isArray(data) && data.every((item) => "items" in item);
  };
  const { selectedProduct } = useProductStore();

  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState<boolean>(false);

  const loadMoreReviews = async () => {
    setIsLoadMoreLoading(true);
    try {
      const resReviews = await AmazonService.reviews.getOwnProducts(
        selectedProduct.asin,
        visibleReviews,
        10,
      );
      setProductOneReviews([...productOne.reviews, ...resReviews]);
      setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 10);
    } catch (error) {
      console.error("Error loading more reviews:", error);
    } finally {
      setIsLoadMoreLoading(false);
    }
  };

  const handleReviews = async () => {
    try {
      const spider = new CategoriesService();
      const resReviews = await spider.getCategories(selectedProduct.asin);

      if (isCategoryArray(resReviews)) {
        const matchingItem = resReviews
          .flatMap((category) => category.items)
          .find((item) => item.asin === selectedProduct.asin);

        if (matchingItem) {
          setScore(matchingItem.score || null);
        }
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      handleReviews();
    }
  }, [selectedProduct]);

  const handleStats = async () => {
    try {
      const spider = new CategoriesService();
      const resReviews = await spider.getStats(selectedProduct.asin);
      setStats(resReviews);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      handleStats();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.asin) {
      clearProductOne();

      const fetchInsights = async () => {
        try {
          const { hypotheses, phrases, problems, reviews } =
            await loadInsideProductData(selectedProduct);
          const spider = new SpiderService();

          const spiderDots = await spider.getSpiderInfo(
            selectedProduct.asin,
            type,
          );

          setProductOneSpiderTitles(formattedKeys(spiderDots));
          setProductOneSpiderValues(
            Object.values(spiderDots).map((value) =>
              parseFloat(value.toFixed(1)),
            ),
          );

          setProductOneHypotheses(hypotheses);
          setProductOnePhrases(phrases);
          setProductOneProblems(problems);
          setProductOneReviews(reviews);
        } catch (error) {
          console.error("Error fetching insights:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchInsights();
    }
  }, [
    selectedProduct,
    setProductOneHypotheses,
    setProductOneAspects,
    setProductOnePhrases,
    setProductOneProblems,
    clearProductOne,
  ]);

  return (
    <PageWrapper>
      <Header title={type === "my-product" ? "My product" : "Overview mode"} />
      <ProductInfo type={type} />
      <div>
        <ExecutiveSummary hideSwitch={true} />
      </div>
      {selectedProduct && <ReviewScore score={score} stats={stats} />}
      {productOne.phrases.length > 0 && (
        <AspectsAndPrases
          spiderTitles={productOne.spiderTitles}
          spiderValues={productOne.spiderValues}
          phrases={productOne.phrases}
        />
      )}
      {productOne.problems.length > 0 && (
        <TopProblems problems={productOne.problems} />
      )}
      {isLoading && (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}

      {selectedProduct && !isLoading && productOne.problems.length > 0 && (
        <Reviews
          reviews={productOne.reviews}
          visibleReviews={visibleReviews}
          loadMoreReviews={loadMoreReviews}
          isLoadingMore={isLoadMoreLoading}
        />
      )}

      {/* {selectedProduct && productOne.problems.length > 0 && <TopPerformance />} */}
    </PageWrapper>
  );
};
