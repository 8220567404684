import classNames from "classnames";
import styles from "./styles.module.scss";
import InputFile from "../InputFile";
import UserGuidePopover from "../UserGuidePopover";
import { ImageData } from "../../mock";
import AccentLight from "../AccentLight";
import { Spin } from "antd";

const AsinTab = ({
  items,
  onChange,
  onUploadImage,
  isLoading = false,
  index,
  setIndex,
  UserGuide,
  setUserGuide,
  staticData,
  currStep,
  setCurrUserStepGuide,
  setShowModal,
}: {
  items: string[];
  onChange: (index: number) => void;
  onUploadImage: (file: File | null) => void;
  isLoading?: boolean;
  index: number;
  setIndex: (index: number) => void;
  UserGuide: boolean;
  staticData?: ImageData[];
  currStep: number;
  setCurrUserStepGuide: (value: number) => void;
  setShowModal: (value: boolean) => void;
  setUserGuide: (value: boolean) => void;
}) => {
  const handleChange = (index: number) => {
    if (isLoading || (UserGuide && index !== 0)) {
      return;
    }

    onChange(index);
    setIndex(index);
  };

  const handleUploadImage = (file: File | null) => {
    if (isLoading) return;
    if (UserGuide) return;
    onUploadImage(file);
  };

  const content = (
    <ul className={classNames(styles.assets)}>
      {(UserGuide ? staticData : items).map((image, i) => {
        const backgroundImage = UserGuide ? image.thumb : image;
        const listItem = (
          <li
            key={i}
            className={classNames(
              styles.image,
              i === index && styles["image--active"],
              UserGuide && i !== 0 && styles["image--disabled"],
            )}
            style={{ backgroundImage: `url(${backgroundImage})` }}
            onClick={() => handleChange(i)}
          ></li>
        );

        return UserGuide && i === 0 ? (
          <AccentLight
            key={i}
            customCSS={{ borderRadius: "8px", border: "1px solid #407ab1" }}
          >
            {listItem}
          </AccentLight>
        ) : (
          listItem
        );
      })}
    </ul>
  );

  return (
    <>
      {UserGuide ? (
        <AccentLight customCSS={{ borderRadius: "50px" }}>
          <InputFile onChange={handleUploadImage} />
        </AccentLight>
      ) : (
        <InputFile onChange={handleUploadImage} />
      )}
      <div
        className={classNames(
          styles.assetsWrapper,
          isLoading && styles.loading,
        )}
      >
        {isLoading && <Spin size="large" className={styles.spin} />}
        {UserGuide && currStep === 0 ? (
          <UserGuidePopover
            placement="rightTop"
            step={currStep}
            text={0}
            nextStep={1}
            setCurrUserStepGuide={setCurrUserStepGuide}
            additionalHandlers={{ setShowModal }}
            setUserGuide={setUserGuide}
          >
            {content}
          </UserGuidePopover>
        ) : (
          content
        )}
      </div>
      <button
        hidden
        className={"button button--sm"}
        onClick={() => setIndex(-1)}
      >
        reset
      </button>
    </>
  );
};

export default AsinTab;
