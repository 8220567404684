import {
  calculateIncrease,
  WidgetData,
} from "@pages/AdsAnalitics/components/Campaigns/utils";
import { AdsSalesApi } from "./ads-sales/ads-sales.api";
import {
  MathService,
  WidgetMetricKey,
  WidgetMetrics,
} from "./math/math.service";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WIDGET_METRICS_CONFIG } from "./math/math.const";

interface MetricProcessingResult {
  value: string;
  originalValue: number;
  difference: string;
}

export class WidgetClass {
  public widgetCount: number;
  private mathService: MathService;
  private adsSalesApi: AdsSalesApi;

  constructor() {
    this.widgetCount = 0;
    this.mathService = new MathService();
    this.adsSalesApi = new AdsSalesApi();
  }

  public getWidgetCount(): number {
    return this.widgetCount;
  }

  public setWidgetCount(count: number): void {
    this.widgetCount = count;
  }

  public async getWidgetsMetrics(
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
  ): Promise<Partial<WidgetMetrics> & { compare?: Partial<WidgetMetrics> }> {
    const metrics = await this.mathService.getAllMetrics(date_start, date_end);

    if (compareStart && compareEnd) {
      const compareMetrics = await this.adsSalesApi.getWidgetsComparePromise(
        date_start,
        date_end,
        compareStart,
        compareEnd,
      );

      return {
        ...metrics,
        compare: compareMetrics as Partial<WidgetMetrics>,
      };
    }

    return metrics;
  }

  private processMetricValue(
    metrics: any,
    key: WidgetMetricKey,
    compareType?: "raw" | "percent",
  ): MetricProcessingResult {
    const cuttedKey =
      key.startsWith("ad") && !metrics[key] ? key.slice(2) : key;
    const cuttedCompareKey =
      key.startsWith("ad") && !metrics.compare[key] ? key.slice(2) : key;
    const value = metrics[cuttedKey] ?? this.generateRandomValueForKey(key);

    let compareValue = "0";
    if (metrics.compare) {
      compareValue = this.calculateCompareValue(
        metrics,
        cuttedCompareKey,
        value,
        compareType,
      );
    }

    return {
      value: value.toString(),
      originalValue: value,
      difference: compareValue,
    };
  }

  private calculateCompareValue(
    metrics: any,
    key: string,
    currentValue: number,
    compareType?: "raw" | "percent",
  ): string {
    const compareMetric =
      metrics.compare[key] ?? this.generateRandomValueForKey(key);

    if (compareType === "raw") {
      const formattedValue = compareMetric || "0";
      return compareMetric > 0 ? "+" + formattedValue : formattedValue;
    }

    return calculateIncrease(currentValue, compareMetric).increase;
  }

  public async getWidgetsData(
    selectedKeys: WidgetMetricKey[],
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
    compareType?: "raw" | "percent",
  ): Promise<IWidget[]> {
    const metrics = await this.getWidgetsMetrics(
      date_start,
      date_end,
      compareStart,
      compareEnd,
    );

    return selectedKeys.map((key) => {
      const { value, originalValue, difference } = this.processMetricValue(
        metrics,
        key,
        compareType,
      );

      return {
        id: key,
        name: WidgetClass.convertKeyToName(key),
        value,
        originalValue,
        difference,
        key,
      };
    });
  }

  formatValue = (key: string, value: number) => {
    const camelKey = this.convertToCamelCase(key);
    const config =
      WIDGET_METRICS_CONFIG[key] || WIDGET_METRICS_CONFIG[camelKey];

    if (!config?.format) {
      console.warn(`No format found for key: ${key} (converted: ${camelKey})`);
      return Math.floor(Number(value)).toLocaleString("en-US");
    }

    switch (config.format) {
      case "currency":
        return `$${Math.floor(Number(value)).toLocaleString("en-US")}`;
      case "percent-decimal":
        return `${(Number(value) * 100).toFixed(2)}%`;
      case "percent":
        return `${Math.floor(Number(value) * 100)}%`;
      case "number":
        return Math.floor(Number(value)).toLocaleString("en-US");
      case "decimal":
        return Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      case "currency-decimal":
        return `$${Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      default:
        return Math.floor(Number(value)).toLocaleString("en-US");
    }
  };

  private convertToCamelCase = (key: string): string => {
    // Remove _what_if suffix first
    const baseKey = key.toLowerCase().replace(/_what_if$/, "");

    // Special cases
    const specialCases: { [key: string]: string } = {
      roas: "adRoas",
      acos: "adAcos",
      cpc: "adCpc",
      cpm: "adCpm",
    };

    if (specialCases[baseKey]) {
      return specialCases[baseKey];
    }

    // Convert remaining snake_case to camelCase
    return baseKey.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  };

  public static convertKeyToName(key: string): string {
    return WIDGET_METRICS_CONFIG[key]?.label;
  }

  generateRandomValueForKey(key: string): number {
    const lowerKey = key.toLowerCase();

    if (
      lowerKey.includes("sales") ||
      lowerKey.includes("spend") ||
      lowerKey.includes("margin") ||
      lowerKey.includes("profit")
    ) {
      return Math.floor(Math.random() * 10000);
    }

    if (
      lowerKey.includes("ctr") ||
      lowerKey.includes("cvr") ||
      lowerKey.includes("acos") ||
      lowerKey.includes("rate") // inStockRate, etc.
    ) {
      return Math.random();
    }

    if (lowerKey.includes("roas")) {
      return Math.random() * 10;
    }

    if (lowerKey.includes("cpc")) {
      return Math.random() * 5;
    }

    return Math.floor(Math.random() * 10000);
  }
}
