import { ExpandableTable } from "@components/ExpandebleTable";
import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { TabsTypeList } from "./Tabs";
import { getColumns, getSelectData } from "./utils";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { Select } from "@components/Select";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { Option } from "@components/Select";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { CanvasApi } from "@services/canvas/canvas.api";
import {
  fetcherMap,
  level1Map,
  level2Map,
  toIsoDate,
  updateRecordChildren,
} from "../CampaignTable/utils";
import { formatData } from "../utils";
import { INestedData, TableItem } from "../utils.types";

interface WhatIfTableProps {
  hideAsin?: boolean;
}

export const WhatIfTable = ({ hideAsin }: WhatIfTableProps) => {
  const canvasApi = useMemo(() => new CanvasApi(), []);
  const { keys, setKeys, sankeyTab, setSankeyTab, adSlides } =
    useAdsAnaliticsStore();
  const { adSpend: saturation } = adSlides;
  const { dateRange } = useDashboardStore();
  const { startDate, endDate } = dateRange;
  const from = toIsoDate(startDate);
  const to = toIsoDate(endDate);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCampaignsKeys, setSelectedCampaignsKeys] = useState([]);
  const [selectedPerformanceKeys, setSelectedPerformanceKeys] = useState([]);
  const [selectedTargetingKeys, setSelectedTargetingKeys] = useState([]);
  const [selectedAddTypeKeys, setSelectedAddTypeKeys] = useState([]);
  const [selectedMatchTypeKeys, setSelectedMatchTypeKeys] = useState([]);
  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();
  const [temporaryData, setTemporaryData] = useState<TableItem[] | undefined>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (selectedRowKeys: any) => {
    const { data, colName } = getSelectData(sankeyTab, tableData);

    const flattenedArray = data.flatMap((item) => [
      item,
      ...(item.children || []),
    ]);

    const keysNames = flattenedArray
      .filter((item) => {
        return selectedRowKeys?.includes(item.key) && item.key.includes("-");
      })
      .map((item) => item[colName]);

    setKeys({ ...keys, [colName]: keysNames });

    switch (sankeyTab) {
      case "Campaigns":
        setSelectedCampaignsKeys(selectedRowKeys);
        break;
      case "Performance":
        setSelectedPerformanceKeys(selectedRowKeys);
        break;
      case "Ad Type":
        setSelectedAddTypeKeys(selectedRowKeys);
        break;
      case "Match type":
        setSelectedMatchTypeKeys(selectedRowKeys);
        break;
      default:
        setSelectedTargetingKeys(selectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys: (() => {
      switch (sankeyTab) {
        case "Campaigns":
          return selectedCampaignsKeys;
        case "Performance":
          return selectedPerformanceKeys;
        case "Ad Type":
          return selectedAddTypeKeys;
        case "Match type":
          return selectedMatchTypeKeys;
        default:
          return selectedTargetingKeys;
      }
    })(),
    onChange: onSelectChange,
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const productsService = new ProductsService();
      try {
        const data = await productsService.getOwnProducts();
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [asin]);

  /**
   * Fetches performance data when the selected tab is "Performance".
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fetchMethod = fetcherMap[sankeyTab];
        if (!fetchMethod) return;

        const fetchedData = await fetchMethod(from, to, saturation);

        const shapedData: INestedData[] = fetchedData.map((item, index) => ({
          ...item,
          key: index,
          level: 1,
          hasFetchedChildren: false,
          children: [],
        }));
        setExpandedRowKeys([]);
        setTemporaryData(shapedData);
      } catch (error) {
        console.error("Error fetching performance data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sankeyTab, dateRange, canvasApi, saturation]);

  useEffect(() => {
    const finalData = formatData({
      data: temporaryData || [],
    });

    setTableData(finalData);
  }, [temporaryData]);

  useEffect(() => {
    setTableData([]);
  }, [sankeyTab]);

  /**
   * Handles row expansion.
   * On expand, we fetch children data (campaigns / search terms / targets, etc.).
   * On collapse, we remove them from `expandedRowKeys` and optionally clear children.
   */
  const handleExpand = async (expanded: boolean, record: any) => {
    if (!expanded) {
      setExpandedRowKeys((prev) => prev.filter((k) => k !== record.key));
      setTemporaryData(
        (prev) => prev && updateRecordChildren(prev, record.key, []),
      );
      return;
    }

    setExpandedRowKeys((prev) => [...prev, record.key]);

    try {
      setIsLoading(true);
      const getData =
        record.level == 1 ? level1Map[sankeyTab] : level2Map[sankeyTab];

      if (!getData) return;

      const childrenData = await getData(from, to, record, saturation);

      const shapedChildren = childrenData.map((child: any, index: number) => ({
        ...child,
        key: `${record.key}-${index}`,
        level: record.level + 1,
        hasFetchedChildren: false,
        performance: child.targeting_text || child.campaign_name,
        campaign_name: child.search_term,
        ad_type: child.search_term || child.campaign,
        target: child.campaign_name,
        match_type: child.target || child.campaign_name,
        match_type_key: child.match_type,
        performanceKey: child.performance,
        ad_typeKey: record.ad_type,
        funnelKey: record.funnel,
        children: [],
      }));

      setTemporaryData(
        (prev) =>
          prev && updateRecordChildren(prev, record.key, shapedChildren),
      );
    } catch (error) {
      console.error("Error fetching children data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {!hideAsin && (
        <div className={styles.inputBox}>
          <h2 className={styles.asinText}>ASIN</h2>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => {
              setAsin(value);
            }}
            className={styles.asin}
          />
        </div>
      )}
      <div className={styles.tabs}>
        <TabsTypeList selectedTab={sankeyTab} setSelectedTab={setSankeyTab} />
      </div>
      <div className={styles.table}>
        <ExpandableTable
          columns={getColumns(sankeyTab)}
          data={tableData}
          rowSelection={rowSelection}
          scroll={{ x: 1000, y: 300 }}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            rowExpandable: () => false,
          }}
          adSalesTab={sankeyTab}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
