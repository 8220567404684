import styles from "./styles.module.scss";
import classNames from "classnames";
import Minus from "@assets/icons/minus";
import { WidgetClass } from "@services/widget.service";
import { FormatDataProps, PerformanceColor, TableItem } from "./utils.types";

const widgetClass = new WidgetClass();

interface ValueWithPercentage {
  value: string;
  percentage: string;
}

export const isValueWithPercentage = (
  value: unknown,
): value is ValueWithPercentage => {
  if (!value || typeof value !== "object") {
    return false;
  }

  const obj = value as Record<string, unknown>;
  return "value" in obj && "percentage" in obj;
};

export const getPerformanceColor = (performance: string): PerformanceColor => {
  const colorMap: Record<string, PerformanceColor> = {
    Good: "green",
    Mid: "yellow",
    Poor: "darkRed",
    "No Sales": "red",
  };

  return colorMap[performance] || "yellow";
};

export const shouldApplyColor = (key: string, color: PerformanceColor) =>
  color &&
  key !== "performance" &&
  key !== "key" &&
  key !== "campaign" &&
  key !== "children" &&
  key !== "level";

export const formatData = ({
  data,
  openTooltip,
  openedTooltip,
  keyTooltip,
  setKeyTooltip,
  keyword,
  setKeywords,
  withKeywords,
}: FormatDataProps): TableItem[] => {
  if (!data?.length) return [];
  return data.map((item) => {
    const newItem = { ...item };
    const color = newItem.performance
      ? getPerformanceColor(newItem.performance)
      : undefined;

    // Format numeric values
    Object.entries(newItem).forEach(([key, value]) => {
      if (typeof value === "number") {
        newItem[key] = widgetClass.formatValue(key, value) || value;
      }

      if (value === null) {
        newItem[key] = "-";
      }

      if (shouldApplyColor(key, color)) {
        newItem[key] = renderColoredCell(newItem[key], color);
      }

      if (isValueWithPercentage(value)) {
        newItem[key] = renderPercentageCell(newItem[key], color);
      }
    });

    // Process children recursively
    if (newItem.children) {
      newItem.children = formatData({
        data: newItem.children,
        openTooltip,
        openedTooltip,
        keyTooltip,
        setKeyTooltip,
        keyword,
        setKeywords,
        withKeywords,
        parentAsin: newItem.campaign,
      });
    }

    return newItem;
  });
};

export const renderColoredCell = (value: any, color: PerformanceColor) => (
  <div className={classNames(styles.cell, color)}>{value}</div>
);

export const renderPercentageCell = (
  { value, percentage }: { value: string; percentage: string },
  color: PerformanceColor,
) => {
  const trend = percentage.startsWith("+")
    ? "up"
    : percentage.startsWith("-")
      ? "down"
      : "neutral";

  return (
    <div className={classNames(styles.cell, color)}>
      <span>{value}</span>
      <span className={classNames(styles.percentage, styles[trend])}>
        {trend === "neutral" && (
          <>
            <Minus />
            {percentage.replace(/[+-]/g, "")}
          </>
        )}
      </span>
    </div>
  );
};
