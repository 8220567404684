import { ArrowDown, ArrowUp } from "lucide-react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import classNames from "classnames";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { getProfitColor, formatName, getValueWithLabel } from "./utils";
import { useIsMobile } from "src/utils/isMobile";
import { useMemo } from "react";
import { WidgetClass } from "@services/widget.service";

interface MetricCardProps {
  metric: IWidget;
  metricColor?: string;
  goalValue?: number;
  maxValue?: number;
}

export const MetricCard = ({
  metric,
  metricColor,
  goalValue,
  maxValue,
}: MetricCardProps) => {
  const widgetClass = useMemo(() => new WidgetClass(), []);
  const { adSlides } = useAdsAnaliticsStore((state) => ({
    adSlides: state.adSlides,
  }));
  const isMobile = useIsMobile();
  let metricValue = Number(metric.originalValue);
  const maxMetricValue =
    maxValue || goalValue
      ? Math.max(goalValue, metricValue) * 1.6
      : metricValue * 1.4;
  let color = metricColor;
  const pieWidth = isMobile ? 165 : 190;
  const strokeWidth = isMobile ? 13 : 30;

  const isForecastNeeded = Object.values(adSlides).some((value) => value !== 0);

  const profit =
    typeof metric?.difference === "string"
      ? metric.difference.startsWith("+")
      : Number(metric?.difference) > 0;
  if (!color) {
    color = profit ? "#80C67A" : "#FE5858";
  }

  if (isForecastNeeded) {
    const forecastSumRaw = Object.values(adSlides).reduce(
      (acc, value) => acc + value,
      0,
    );

    const multiplier = metricValue / 100;

    const forecastSum = forecastSumRaw * multiplier;

    metricValue = metricValue + forecastSum;
    color = getProfitColor(metric.name, metricValue, maxMetricValue);
  }

  return (
    <div
      className={classNames(
        styles.metricCard,
        !profit && styles.metricCardSmall,
      )}
    >
      <div className={styles.metricHeader}>{formatName(metric.name)}</div>
      <div className={styles.metricBody}>
        <PieChart
          width={pieWidth}
          height={pieWidth / 2}
          className={styles.metricPie}
        >
          <Pie
            data={[{ value: 100 }]}
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={
              goalValue > 0 ? (isMobile ? 65 : 80) : pieWidth / 2 - strokeWidth
            }
            outerRadius={goalValue > 0 ? (isMobile ? 75 : 95) : pieWidth / 2}
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
            fill={"#2E2E2E"}
            stroke="none"
          ></Pie>
          <Pie
            data={[{ value: metricValue }, { value: maxMetricValue }]}
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={
              goalValue > 0 ? (isMobile ? 65 : 80) : pieWidth / 2 - strokeWidth
            }
            outerRadius={goalValue > 0 ? (isMobile ? 75 : 95) : pieWidth / 2}
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
            opacity={goalValue > 0 ? 0.6 : 1}
          >
            <Cell fill={goalValue ? "#255FA0" : color} stroke="none" />
            <Cell fill="none" stroke="none" />
          </Pie>
          {goalValue > 0 && (
            <Pie
              data={[{ value: 100 }]}
              cx="50%"
              cy="90"
              startAngle={180}
              endAngle={0}
              innerRadius={isMobile ? 55 : 65}
              outerRadius={isMobile ? 65 : 80}
              paddingAngle={0}
              dataKey="value"
              cornerRadius={32}
              fill={"#2E2E2E"}
              stroke="none"
            ></Pie>
          )}
          {goalValue > 0 && (
            <Pie
              data={[{ value: goalValue }, { value: maxMetricValue }]}
              cx="50%"
              cy="90"
              startAngle={180}
              endAngle={0}
              innerRadius={isMobile ? 55 : 65}
              outerRadius={isMobile ? 65 : 80}
              paddingAngle={0}
              dataKey="value"
              cornerRadius={32}
            >
              <Cell fill={color} stroke="none" />
              <Cell fill="none" stroke="none" />
            </Pie>
          )}
        </PieChart>
        <div className={styles.metricValuesBox}>
          {metric.difference && (
            <div className={styles.metricChange} style={{ color }}>
              {widgetClass.formatValue(metric.key, Number(metric?.difference))}
              {profit ? <ArrowUp size={12} /> : <ArrowDown size={12} />}
            </div>
          )}
          <div className={styles.metricValuesBox}>
            {goalValue > 0 && (
              <div className={styles.metricValue}>
                {widgetClass.formatValue(metric.key, goalValue)}
              </div>
            )}
            <h4
              className={classNames(
                styles.metricValue,
                goalValue > 0 && styles.withGoals,
              )}
            >
              {goalValue > 0 && "Current: "}
              {widgetClass.formatValue(metric.key, metricValue)}
            </h4>
          </div>
        </div>
        <div className={styles.metricCircle}>
          <svg viewBox="0 0 100 52" version="1.1">
            <circle
              cx="50px"
              cy="50px"
              r="48"
              stroke="#C8A857"
              strokeWidth="2"
              strokeDasharray="0 10"
              strokeLinecap="round"
              fill="transparent"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
