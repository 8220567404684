import ModalBox from "@components/ModalBox";
import { Checkbox, Switch, Tooltip } from "antd";
import styles from "./styles.module.scss";
import { useState } from "react";
import { WidgetClass } from "@services/widget.service";
import { SalesChecks } from "../../utils";
import {
  WIDGET_METRIC_GROUPS,
  WIDGET_METRICS_CONFIG,
} from "@services/math/math.const";
import classNames from "classnames";

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  isAdvancedView: boolean;
  handleToggleView: (checked: boolean) => void;
  checks: SalesChecks;
  setChecks: (checks: SalesChecks) => void;
  isForecasted: boolean;
  setIsForecasted: (value: boolean) => void;
  choosedGroups: string[] | "all";
}

const MAX_METRICS = 6;

export const SettingsModal = ({
  open,
  onClose,
  isAdvancedView,
  handleToggleView,
  checks,
  setChecks,
  isForecasted,
  setIsForecasted,
  choosedGroups,
}: SettingsModalProps) => {
  let selectedCount = Object.values(checks).filter(Boolean).length;
  const [forecastChecked, setForecastChecked] = useState(isForecasted);
  const [isTableView, setIsTableview] = useState(isAdvancedView);
  const [choosedDataPoints, setChoosedDataPoints] = useState(checks);
  const [isMaxMetrics, setIsMaxMetrics] = useState(
    selectedCount >= MAX_METRICS,
  );

  const handleReset = () => {
    setChoosedDataPoints(checks);
    setForecastChecked(isForecasted);
    setIsTableview(isAdvancedView);
    setIsMaxMetrics(false);
  };

  const handleApply = () => {
    setChecks(choosedDataPoints);
    setIsForecasted(forecastChecked);
    handleToggleView(isTableView);
    onClose();
  };

  const filteredGroups =
    choosedGroups === "all"
      ? WIDGET_METRIC_GROUPS
      : WIDGET_METRIC_GROUPS.filter((group) =>
          choosedGroups.includes(group.title),
        );

  return (
    <ModalBox
      title="Sales Performance"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
    >
      <p>Choose view and metrics for «Sales Performance»</p>
      <div className={styles.switchRow}>
        <div className={styles.switchContainer}>
          <span className={styles.switchLabel}>Show lines</span>
          <Switch
            checked={!isTableView}
            onChange={() => setIsTableview(!isTableView)}
          />
          <span className={styles.switchLabel}>Show bars</span>
        </div>
        <div className={styles.switchContainer}>
          <Switch
            checked={forecastChecked}
            onChange={(checked) => setForecastChecked(checked)}
          />
          <span className={styles.switchLabel}>Show Forecast</span>
        </div>
      </div>

      <div className={styles.points}>
        {filteredGroups.map((group) => (
          <div
            key={group.title}
            className={classNames(
              styles.group,
              isMaxMetrics && styles.maxMetrics,
            )}
          >
            <h3>{group.title}</h3>
            {group.keys.map((key) => {
              const isChecked = !!choosedDataPoints[key];

              const handleChange = (newChecked: boolean) => {
                if (!isChecked && newChecked) {
                  if (isMaxMetrics) return;
                  selectedCount++;
                  if (selectedCount >= MAX_METRICS) {
                    setIsMaxMetrics(true);
                  }
                }
                if (isChecked && !newChecked) {
                  selectedCount--;
                  if (selectedCount <= MAX_METRICS) {
                    setIsMaxMetrics(false);
                  }
                }
                setChoosedDataPoints({
                  ...choosedDataPoints,
                  [key]: newChecked,
                });
              };
              return (
                <div key={key} className={styles.row}>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => handleChange(e.target.checked)}
                  />
                  <span className={styles.name}>
                    {WidgetClass.convertKeyToName(key)}
                  </span>
                  <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info}>
                    <button>i</button>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <button className={styles.reset} onClick={handleReset}>
          reset to default
        </button>
        <button className={styles.apply} onClick={handleApply}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};
