import React, { useMemo } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
} from "recharts";
import styles from "./styles.module.scss";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { WidgetClass } from "@services/widget.service";
import { useIsMobile } from "src/utils/isMobile";

interface ChartDimensions {
  margin: { top: number; right: number; left: number; bottom: number };
  tickPadding: { left: number; right: number };
  height: number;
  xAxisDy: number;
}

const getResponsiveDimensions = (isMobile: boolean): ChartDimensions => ({
  margin: isMobile
    ? { top: 20, right: -20, left: -20, bottom: 20 }
    : { top: 20, right: 30, left: 20, bottom: 20 },
  tickPadding: {
    left: isMobile ? 10 : 0,
    right: isMobile ? -10 : 0,
  },
  height: isMobile ? 250 : 350,
  xAxisDy: isMobile ? 5 : 10,
});

interface ChartProps {
  data: any[];
  metrics: string[];
  metricsColors: { [key: string]: string };
  isForecasted: boolean;
}

export const LineChart: React.FC<ChartProps> = ({
  data,
  metrics,
  metricsColors,
  isForecasted,
}) => {
  const widgetClass = new WidgetClass();
  const isMobile = useIsMobile();

  const dimensions = useMemo(
    () => getResponsiveDimensions(isMobile),
    [isMobile],
  );

  return (
    <div className={styles["sales-performance__chart"]}>
      <ResponsiveContainer
        width="100%"
        height={dimensions.height}
        debounce={50}
      >
        <ComposedChart data={data} margin={dimensions.margin}>
          <CartesianGrid
            stroke="#1F1F20"
            vertical={false}
            horizontalCoordinatesGenerator={(props) => {
              const { height } = props;
              return Array.from(
                { length: isMobile ? 5 : 10 },
                (_, i) => height * (i / (isMobile ? 4 : 9)),
              );
            }}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            width={60}
            interval={isMobile ? "preserveStartEnd" : 0}
            tick={{
              fill: "#FFFFFF",
              dy: dimensions.xAxisDy,
              fontSize: isMobile ? 10 : 12,
            }}
            tickLine={false}
          />
          <Tooltip
            content={({ active, payload, label }) => (
              <CustomTooltip
                active={active}
                payload={payload}
                colorMetric={metricsColors}
                label={label}
              />
            )}
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
          />

          {metrics.map((metric, index) => {
            const yAxisId = metric;
            const orientation = index % 2 === 0 ? "left" : "right";
            const tickColor = metricsColors[metric];

            return (
              <React.Fragment key={metric}>
                <YAxis
                  yAxisId={yAxisId}
                  orientation={orientation}
                  stroke={tickColor}
                  tickFormatter={(value: number) =>
                    widgetClass.formatValue(metric, value)
                  }
                  axisLine={false}
                  tickLine={false}
                  domain={["auto", "auto"]}
                  tickCount={isMobile ? 5 : 11}
                  tick={{
                    fill: tickColor,
                    dx: dimensions.tickPadding[orientation],
                    fontSize: isMobile ? 10 : 12,
                  }}
                  width={isMobile ? 35 : 60}
                />
                <Line
                  yAxisId={metric}
                  type="monotone"
                  dataKey={metric}
                  stroke={metricsColors[metric]}
                  strokeWidth={isMobile ? 1 : 2}
                  strokeDasharray={isForecasted ? "5 5" : "0"}
                  dot={false}
                  isAnimationActive={false}
                />
              </React.Fragment>
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
