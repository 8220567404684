export const COLORS_CHART = [
  "#80C67A",
  "#1BB08D",
  "#fc6762",
  "#3a3b7c",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
] as const;

export interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
}

export interface SalesPerformanceProps {
  choosedGroups: string[] | "all";
}

export interface MetricsState {
  [key: string]: boolean;
}

export interface ColorMapping {
  [key: string]: string;
}
