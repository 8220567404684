import React, { useState } from "react";
import { NavItem, createNavItems } from "./navItems";
import { ROUTES } from "src/router/routes";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { useUserStore } from "src/store/user.state";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useMaintenance } from "src/store/maintenance.state";
import { ModalAmazonConnect } from "@components/ModalAmazonConnect";

interface HeaderNavProps {
  menuOpens: boolean;
  setMenuOpens: (state: boolean) => void;
  isAmazonConnectedOrSeller: boolean;
  isAmazonConnectModalOpen: boolean;
  setAmazonConnectModalOpen: (state: boolean) => void;
}

export const HeaderNav: React.FC<HeaderNavProps> = ({
  menuOpens,
  setMenuOpens,
  isAmazonConnectedOrSeller,
  isAmazonConnectModalOpen,
  setAmazonConnectModalOpen,
}) => {
  const { maintenance } = useMaintenance();
  const { t } = useTranslation();
  const { user } = useUserStore();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const isAmazonConnected = user?.is_amazon_connected;

  const popupRef = useOutsideClick(() => {
    setMenuOpen(false);
    setMenuOpens(false);
  });

  const isStaff = user?.is_staff;

  const navItems: NavItem[] = [];
  if (maintenance) {
    navItems.push(
      {
        text: t("label.dashboard"),
        url: `${ROUTES.ROOT}`,
        onClick: () => setMenuOpens(false),
      },
      {
        text: t("Revenue"),
        onClick: () => {
          setMenuOpen(!menuOpen);
        },
      },
      {
        text: t("finance"),
        url: "/finance",
        onClick: () => setMenuOpens(false),
      },
      {
        text: t("Supply chain"),
        url: "/supply-chain",
        onClick: () => setMenuOpens(false),
      },
      {
        text: t("Market Intelligence"),
        url: "/market-intelligence",
        onClick: () => setMenuOpens(false),
      },
      {
        text: t("Workflows"),
        url: "/workflows",
        onClick: () => setMenuOpens(false),
      },
      {
        text: t("AUDIT"),
        url: "/canvas",
        onClick: () => setMenuOpens(false),
      },
    );
  } else {
    if (isStaff || isAmazonConnected) {
      navItems.push(
        {
          text: t("My Products"),
          url: "/my-product",
          onClick: () => setMenuOpens(false),
        },
        {
          text: t("CRO Module"),
          url: "/CRO-module",
          onClick: () => setMenuOpens(false),
        },
        {
          text: t("Ad Campaign Manager"),
          url: "/ads-analytics/campaigns",
          onClick: () => setMenuOpens(false),
        },
      );
    }
  }

  if (isStaff) {
    navItems.push({
      text: t("Customers"),
      url: "/customers",
      onClick: () => setMenuOpens(false),
    });
  }

  return (
    <>
      <nav
        className={classNames("app-header__nav", {
          "app-header__nav--open": menuOpens,
        })}
        ref={popupRef}
      >
        {createNavItems(
          navItems,
          menuOpen,
          setMenuOpen,
          popupRef,
          setMenuOpens,
          isStaff ? true : isAmazonConnectedOrSeller,
          setAmazonConnectModalOpen,
        )}
      </nav>
      {!isStaff && (
        <ModalAmazonConnect
          isOpen={isAmazonConnectModalOpen}
          onClose={() => setAmazonConnectModalOpen(false)}
        />
      )}
    </>
  );
};
