import { XAxis, YAxis, Tooltip, ComposedChart, CartesianGrid } from "recharts";
import {
  Checks,
  formatThousands,
  generateForecastData,
  generatePastData,
  getData,
  getInterval,
  getTickProps,
} from "./utils";
import { CustomTooltip } from "./Tooltip";
import { useEffect, useState } from "react";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Spin, Switch } from "antd";
import styles from "./styles.module.scss";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import classNames from "classnames";
import { renderBar, renderLines } from "./ChartSeries";

export interface CampaignsChartProps {
  checks?: Partial<Checks>;
  handleFixChecks?: () => void;
  chartWidth?: number;
}

export const defaultChecks: Partial<Checks> = {
  sales: true,
  spend: true,
  impressions: true,
  ctr: true,
  cvr: true,
  cpc: true,
  acos: true,
  roas: true,
  orders: true,
  unitSolds: true,
  clicks: false,
};

export const CampaignsChart = ({
  checks = defaultChecks,
  handleFixChecks,
  chartWidth = 1302,
}: CampaignsChartProps) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const { isWhatIfEnabled } = useAdsAnaliticsStore((state) => ({
    isWhatIfEnabled: state.isWhatIfEnabled,
  }));
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));

  const [showForecast, setShowForecast] = useState(false);
  const [showPastData, setShowPastData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const startDate = new Date(dateRange.startDate);
      const daysToExtend = Math.ceil(
        (new Date(dateRange.endDate).getTime() -
          new Date(dateRange.startDate).getTime()) /
          (1000 * 60 * 60 * 24),
      );
      startDate.setDate(startDate.getDate() - daysToExtend);

      const requestDateRange =
        showPastData && isWhatIfEnabled
          ? {
              ...dateRange,
              startDate: startDate,
            }
          : { ...dateRange };

      const data = await getData(requestDateRange, checks);
      if (!data) {
        setIsLoading(false);
        return;
      }

      Object.keys(data).forEach((key) => {
        if (data[key] === null) {
          delete data[key];
        }
      });

      const transformedData = data.date.map((date: string, index: number) => {
        const obj: any = {
          date,
          isForecast: false,
          isPastData: false,
        };

        if (Object.prototype.hasOwnProperty.call(data, "sales")) {
          obj.sales = data.sales?.[index] || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "spend")) {
          obj.spend = data.spend?.[index] || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "impressions")) {
          obj.impressions = data.impressions?.[index] || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "ctr")) {
          obj.ctr = Number(data.ctr?.[index]) * 100 || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "cvr")) {
          obj.cvr = Number(data.cvr?.[index]) * 100 || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "cpc")) {
          obj.cpc = data.cpc?.[index] || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "acos")) {
          obj.acos = Number(data.acos?.[index]) * 100 || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "roas")) {
          obj.roas = data.roas?.[index] || 0;
        }
        if (Object.prototype.hasOwnProperty.call(data, "unitSolds")) {
          obj.unitSolds = data.unitSolds?.[index] || 0;
        }
        return obj;
      });

      let finalData = [...transformedData];

      if (showPastData && isWhatIfEnabled) {
        const pastData = generatePastData(
          transformedData,
          daysToExtend,
          transformedData[daysToExtend],
          startDate,
        );
        finalData = [...pastData];
      }

      if (showForecast && isWhatIfEnabled) {
        const lastDate = transformedData[transformedData.length - 1];
        const forecastedData = generateForecastData(
          showPastData ? transformedData.length / 2 : transformedData.length,
          lastDate,
          transformedData[transformedData.length - 1].date,
        );
        finalData = [...finalData, ...forecastedData];
      }

      setData(finalData);
      setIsLoading(false);
    };
    fetchData();
  }, [dateRange, checks, showForecast, showPastData, isWhatIfEnabled]);

  const preparedData = [
    {
      name: "Past",
      data: data?.filter((item: any) => item.isPastData),
    },
    {
      name: "Actual",
      data: data?.filter((item: any) => !item.isForecast && !item.isPastData),
    },
    {
      name: "Forecast",
      data: data?.filter((item: any) => item.isForecast),
    },
  ];

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        {isWhatIfEnabled && (
          <div className={styles.whatIf}>
            <div className={styles.toggle__container}>
              <Switch
                checked={showForecast}
                onChange={() => {
                  setShowForecast(!showForecast);
                }}
                className={classNames(
                  styles.toggle,
                  showForecast && styles.toggle__active,
                )}
                id="forecast"
                defaultChecked
              />
              <label htmlFor="forecast">Show Forecast</label>
            </div>
            <div className={styles.toggle__container}>
              <Switch
                checked={showPastData}
                onChange={() => {
                  if (!showPastData && handleFixChecks) {
                    handleFixChecks();
                  }
                  setShowPastData(!showPastData);
                }}
                className={classNames(
                  styles.toggle,
                  showPastData && styles.toggle__active,
                )}
                id="pastData"
                defaultChecked
              />
              <label htmlFor="pastData">Show Past Data</label>
            </div>
          </div>
        )}
        <ComposedChart
          width={chartWidth}
          height={423}
          data={preparedData.flatMap((s) => s.data)}
          margin={{
            top: 0,
            right: -10,
            left: -20,
            bottom: -10,
          }}
        >
          <CartesianGrid stroke="#1F1F20" />
          <Tooltip
            content={({ active, payload, label, coordinate }) => (
              <CustomTooltip
                active={active}
                payload={payload}
                label={label}
                coordinate={coordinate}
                chartWidth={1302}
              />
            )}
            cursor={{
              fill: "transparent",
              stroke: "white",
              strokeDasharray: 4,
            }}
          />
          <XAxis
            allowDuplicatedCategory={false}
            dataKey="date"
            type="category"
            tickLine={false}
            interval={getInterval(data.length)}
            tick={getTickProps(data?.date?.length)}
          />
          <XAxis
            allowDuplicatedCategory={false}
            dataKey="date"
            type="category"
            scale="point"
            xAxisId="lineAxis"
            hide={true}
            interval={0}
          />
          <XAxis
            allowDuplicatedCategory={false}
            dataKey="date"
            type="category"
            scale="point"
            xAxisId="centerAxis"
            hide={true}
            interval={0}
          />
          {renderBar(
            preparedData.flatMap((o) => o.data),
            checks,
          )}
          {/* 1: Impressions */}
          <YAxis
            yAxisId="left1"
            orientation="left"
            stroke="#6B4DBA"
            tickFormatter={formatThousands}
            tickLine={false}
            axisLine={{
              stroke: "#fff",
            }}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            includeHidden
            tickCount={11}
          />
          {/* 2: Sales, Spend */}
          <YAxis
            yAxisId="left2"
            orientation="left"
            stroke="rgb(128, 198, 122)"
            tickFormatter={(tick) => `$${formatThousands(tick)}`}
            hide={!checks.sales && !checks.spend}
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            includeHidden
            tickCount={11}
          />
          {/* 3: NTB Orders, Orders */}
          <YAxis
            yAxisId="left3"
            orientation="left"
            stroke="#255FA0"
            tickFormatter={formatThousands}
            hide={!checks.orders && !checks.unitSolds}
            axisLine={false}
            tickLine={false}
            tickCount={11}
          />
          {/* 4: CVR, CTR, ACOS */}
          <YAxis
            yAxisId="right1"
            orientation="right"
            stroke="#FFF"
            tickFormatter={(tick) => `${tick}%`}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            tickCount={11}
          />
          {/* 5: CPC */}
          <YAxis
            yAxisId="right2"
            orientation="right"
            stroke="#E6FF4B"
            tickFormatter={(tick) => `$${tick.toFixed(2)}`}
            hide={!checks.cpc}
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            tickCount={11}
          />
          {/* 6: ROAS */}
          <YAxis
            yAxisId="right3"
            orientation="right"
            stroke="#F19867"
            tickFormatter={(tick) => `${tick.toFixed()}`}
            hide={!checks.roas}
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
            tickCount={11}
          />
          {renderLines(preparedData, checks)}
        </ComposedChart>
      </div>
    </div>
  );
};
