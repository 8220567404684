import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ExpandableTable } from "@components/ExpandebleTable";
import { columns, data } from "./mock";
import { OrganicChart } from "./OrganicChart";
import { ArrowLeft } from "lucide-react";
import { GridStack } from "gridstack";
import { updateElementSize } from "@pages/CanvasEditor/Canva";

interface OrganicSalesTableProps {
  gridRef?: React.RefObject<GridStack>;
  id?: string;
}

export const OrganicSalesTable: React.FC<OrganicSalesTableProps> = ({
  gridRef,
  id,
}) => {
  const [selections, setSelections] = useState<any[]>([]);
  const [isChartOpen, setIsChartOpen] = useState(false);

  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  return (
    <div className={styles.container}>
      {isChartOpen ? (
        <>
          <div className={styles.chart__title}>
            <button
              onClick={() => {
                setIsChartOpen(false);
                updateElementSize({
                  id: id || "",
                  size: { height: 58 },
                  gridRef: gridRef,
                });
              }}
            >
              <ArrowLeft width={20} height={20} />
            </button>
            <h3>CTR & CVR from organic rank</h3>
          </div>
          <OrganicChart />
        </>
      ) : (
        <>
          <div className={styles.header}>
            <h3>Organic Sales</h3>
            <button
              className={styles.button}
              onClick={() => {
                setIsChartOpen(true);
                updateElementSize({
                  id: id || "",
                  size: { height: 70 },
                  gridRef: gridRef,
                });
              }}
            >
              switch to CTR & CVR from organic rank
            </button>
          </div>
          <ExpandableTable
            columns={columns}
            data={data}
            withCustomScroll
            rowSelection={rowSelections}
          />
        </>
      )}
    </div>
  );
};
