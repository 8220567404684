import ModalBox from "@components/ModalBox";
import { Checkbox, Switch, Tooltip } from "antd";
import styles from "./styles.module.scss";
import { useState } from "react";
import { nameChoosedMapping } from "../utils";
import { InfoIcon } from "lucide-react";
import { WidgetClass } from "@services/widget.service";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  setDataPoints: (data: Record<string, boolean>) => void;
  dataPoints: Record<string, boolean>;
  showForecast: boolean;
  setShowForecast: (showForecast: boolean) => void;
  isPoModal: boolean;
  setIsPoModal: (isPoModal: boolean) => void;
}

export const SettingsModal = ({
  open,
  onClose,
  setDataPoints,
  dataPoints,
  showForecast,
  setShowForecast,
  isPoModal,
  setIsPoModal,
}: SettingsModalProps) => {
  const [forecastChecked, setForecastChecked] = useState(showForecast);
  const [poModalChecked, setPoModalChecked] = useState(isPoModal);
  const [choosedDataPoints, setChoosedDataPoints] = useState(dataPoints);

  const handleApply = () => {
    setShowForecast(forecastChecked);
    setIsPoModal(poModalChecked);
    setDataPoints(choosedDataPoints);
    onClose();
  };

  const handleReset = () => {
    setDataPoints(nameChoosedMapping);
    setForecastChecked(false);
    setPoModalChecked(false);
  };

  return (
    <ModalBox
      title="Inventory Performance"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
    >
      <p>Choose view and metrics for «Inventory Performance»</p>
      <div className={styles.switchRow}>
        <div className={styles.switchContainer}>
          <Switch
            checked={forecastChecked}
            onChange={(checked) => setForecastChecked(checked)}
          />
          <span className={styles.switchLabel}>Show Forecast</span>
        </div>
      </div>

      <div className={styles.points}>
        {Object.entries(nameChoosedMapping).map(([key, value]) => (
          <div key={key} className={styles.row}>
            <Checkbox
              checked={choosedDataPoints[key]}
              onChange={(e) => {
                setChoosedDataPoints((prev) => ({
                  ...prev,
                  [key]: e.target.checked,
                }));
              }}
            />
            <span>{WidgetClass.convertKeyToName(key)}</span>
            <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info} placement="right">
              <button>i</button>
            </Tooltip>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <button className={styles.reset} onClick={handleReset}>
          reset to default
        </button>
        <button className={styles.apply} onClick={handleApply}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};
