import {
  adTypeColumnsWithoutNyle,
  funnelColumnsWithoutNyle,
  matchColumnsWithoutNyle,
  campaignsColumnsWithoutNyle,
  targetingColumnsWithoutNyle,
  targetTypeColumnsWithoutNyle,
  performanceColumnsWithoutNyle,
} from "../table.columns";
import { getStarColor } from "@components/ExpandebleTable/KeyTooltip";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { CanvasApi } from "@services/canvas/canvas.api";
import styles from "./styles.module.scss";
import { Checkbox } from "antd";
import StarKeyword from "@assets/icons/StarKeyword";
import { INestedData } from "../utils.types";

export const isTotalFirst = (data: any[]) => {
  if (!data || (data && data.length === 0)) return false;
  return (
    data[0].campaign_name === "Total" ||
    data[0].ad_type === "Total" ||
    data[0].match_type === "Total" ||
    data[0].target === "Total" ||
    data[0].funnel === "Total" ||
    data[0].performance === "Total"
  );
};

export const removeTotalChildren = (children: any[] | undefined): any[] => {
  if (!children || children.length === 0) return [];

  return children.map((child) => {
    if (child.children && child.children.length > 0) {
      return {
        ...child,
        children: removeTotalChildren(child.children),
      };
    }
    return child;
  });
};

/** A small helper to format dates into YYYY-MM-DD. */
export const toIsoDate = (date: Date) => date.toISOString().split("T")[0];

/**
 * Returns columns based on the selected tab.
 */
export const getColumns = (tab: TypeTab) => {
  switch (tab) {
    case "Campaigns":
      return campaignsColumnsWithoutNyle;
    case "Performance":
      return performanceColumnsWithoutNyle;
    case "Ad Type":
      return adTypeColumnsWithoutNyle;
    case "Funnel":
      return funnelColumnsWithoutNyle;
    case "Target Type":
      return targetTypeColumnsWithoutNyle;
    case "Targeting":
      return targetingColumnsWithoutNyle;
    case "Match type":
      return matchColumnsWithoutNyle;
  }
};

/**
 * Formats keyword options for the Select component.
 */
export const formattedKeys = (keys: string[]) => {
  return keys.map((key, index) => (
    <div className={styles.keys__container} key={key}>
      <Checkbox />
      <div className={styles.star}>
        <StarKeyword fill={getStarColor(index)} />
      </div>
      <span>{key}</span>
    </div>
  ));
};

/**
 * We extend the IPerformanceTableData to add fields for nested expansions.
 */

/**
 * A helper to update a node's `children` in a nested array by a matching `recordKey`.
 */
export function updateRecordChildren(
  records: INestedData[],
  recordKey: number,
  children: INestedData[],
): INestedData[] {
  return records.map((item) => {
    if (item.key == recordKey) {
      return {
        ...item,
        children,
        hasFetchedChildren: true,
      };
    } else if (item.children && item.children.length > 0) {
      return {
        ...item,
        children: updateRecordChildren(item.children, recordKey, children),
      };
    }
    return item;
  });
}

const canvasApi = new CanvasApi();

export const fetcherMap: Record<
  string,
  (start: string, end: string, saturation?: number) => Promise<any[]>
> = {
  Performance: canvasApi.getSalesPerformance,
  "Ad Type": canvasApi.getSalesPerformanceAdType,
  "Match type": canvasApi.getSalesPerformanceMatchType,
  Targeting: canvasApi.getSalesPerformanceTargets,
  Funnel: canvasApi.getSalesPerformanceFunnel,
  Campaigns: canvasApi.getSalesPerformanceCampaigns,
};

export const level1Map: Record<
  string,
  (start: string, end: string, r: any, saturation?: number) => Promise<any>
> = {
  Performance: (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceCampaign(s, e, r.performance, saturation),
  "Ad Type": (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceAdTypeCampaign(s, e, r.ad_type, saturation),
  "Match type": (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceMatchTypeCampaign(
      s,
      e,
      r.match_type,
      saturation,
    ),
  Targeting: (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceTargetsCampaigns(s, e, r.target, saturation),
  Funnel: (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceFunnelCampaign(s, e, r.funnel, saturation),
  Campaigns: (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceCampaignsSearchTerms(
      s,
      e,
      r.campaign_name,
      saturation,
    ),
};

export const level2Map: Record<
  string,
  (start: string, end: string, r: any, saturation?: number) => Promise<any>
> = {
  Performance: (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceTarget(
      s,
      e,
      r.performanceKey,
      r.performance,
      saturation,
    ),
  "Ad Type": (s, e, r, saturation) => {
    return canvasApi.getSalesPerformanceAdTypeSearchTerms(
      s,
      e,
      r.ad_typeKey,
      r.ad_type,
      saturation,
    );
  },
  "Match type": (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceMatchTypeTarget(
      s,
      e,
      r.match_type_key,
      r.match_type,
      saturation,
    ),
  Funnel: (s, e, r, saturation) =>
    canvasApi.getSalesPerformanceFunnelSearchTerms(
      s,
      e,
      r.funnelKey,
      r.funnel,
      saturation,
    ),
};
