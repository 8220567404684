export type Checks = Record<string, boolean>;

export const initialChecks: Checks = {
  // impressions: false,
  // CVR: false,
  sales: true,
  adCTR: true,
  // adOrders: false,
  adSpend: true,
  // organicClicks: false,
  // organicUnitsSold: false,
  // subscriptionSales: false,
  // CTR: false,
  // orders: false,
  // spend: false,
  adClicks: true,
  adUnitsSold: true,
  // organicImpressions: false,
  // organicCVR: false,
  // organicSales: false,
  // ntbSales: false,
  // clicks: false,
  // unitSolds: false,
  ACOS: false,
  adImpressions: true,
  adCVR: false,
  adSales: false,
  ROAS: false,
  CPC: false,
  // organicCTR: false,
  // organicOrders: false,
  // organicSpend: false,
  // contributionProfit: false,
};
