import { WIDGET_METRIC_KEYS } from "@services/math/math.const";
import { format } from "date-fns";

export const data = [
  { date: "1 Jan", AdsSales: 200, OrganicSales: 120 },
  { date: "2 Jan", AdsSales: 180, OrganicSales: 140 },
  { date: "3 Jan", AdsSales: 160, OrganicSales: 150 },
  { date: "4 Jan", AdsSales: 230, OrganicSales: 190 },
  { date: "5 Jan", AdsSales: 190, OrganicSales: 110 },
  { date: "6 Jan", AdsSales: 220, OrganicSales: 130 },
  { date: "7 Jan", AdsSales: 210, OrganicSales: 170 },
];

export const COLORS = ["#5295E0", "#80C67A"];
export const legendColorMapping: Record<string, string> = {
  AdsSales: "#5295E0",
  OrganicSales: "#80C67A",
};
export const nameMapping: Record<string, string> = {
  AdsSales: "AdsSales",
  OrganicSales: "OrganicSales",
};

export function generateDateRangeArray(
  startDate: string | Date,
  endDate: string | Date,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  const current = new Date(start);

  while (current <= end) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return dates;
}

export const renderCustomizedBar = (
  props: any,
  activeLabel: string | null,
  color: string,
  isForecasted: boolean,
) => {
  const { x, y, width, height, payload } = props;
  const isActive = payload.date === activeLabel;
  const fillOpacity = isForecasted
    ? 0.5
    : activeLabel === null
      ? 1
      : isActive
        ? 1
        : 0.3;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        fillOpacity={fillOpacity}
        strokeDasharray={isForecasted ? "5 5" : "0"}
        stroke={isForecasted ? color : "none"}
      />
    </g>
  );
};

export type SalesChecks = {
  [key: string]: boolean;
};

export const initialChecks: SalesChecks = {
  totalSales: true,
  adSales: true,
  adSpend: true,
  adImpressions: true,
  adCtr: true,
  adCvr: true,
};

export const generateData = (
  startDate: Date,
  endDate: Date,
  activeChecks: string[],
) => {
  const dates = generateDateRangeArray(startDate, endDate);

  return dates.map((date) => {
    const obj: any = {
      date: format(date, "d MMM"),
    };

    activeChecks.forEach((check) => {
      obj[check] = Math.floor(Math.random() * 1000);
    });

    return obj;
  });
};
