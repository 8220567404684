import { CustomBar } from "@components/BarChart";
import { format } from "date-fns";

interface RenderProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: any;
  dataKey: string;
  activeLabel: string | null;
  showForecast: boolean;
  colorMapping: { [key: string]: { [key: string]: string } };
  index: number;
}

export const data = [
  { date: "1 Jan", available: 1600, reorderPoint: 650, unitsSold: 500 },
  { date: "2 Jan", available: 1800, reorderPoint: 420, unitsSold: 500 },
  { date: "3 Jan", available: 1650, reorderPoint: 250, unitsSold: 900 },
  {
    date: "4 Jan",
    available: 1200,
    reorderPoint: 500,
    inTransit: 300,
    unitsSold: 700,
  },
  {
    date: "5 Jan",
    available: 900,
    reorderPoint: 360,
    inTransit: 440,
    unitsSold: 200,
  },
  {
    date: "6 Jan",
    available: 1400,
    reorderPoint: 450,
    inTransit: 500,
    unitsSold: 600,
  },
  {
    date: "7 Jan",
    available: 1300,
    reorderPoint: 400,
    inTransit: 400,
    unitsSold: 800,
  },
];

export const colorMapping: Record<string, Record<string, string>> = {
  "1 Jan": {
    available: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
  },
  "2 Jan": {
    available: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
  },
  "3 Jan": {
    available: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
  },
  "4 Jan": {
    available: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#F19867",
    unitsSold: "#5C5C5A",
  },
  "5 Jan": {
    available: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#6B4DBA",
    unitsSold: "#5C5C5A",
  },
  "6 Jan": {
    available: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#F19867",
    unitsSold: "#5C5C5A",
  },
  "7 Jan": {
    available: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#6B4DBA",
    unitsSold: "#5C5C5A",
  },
};

export const nameChoosedMapping: Record<string, boolean> = {
  available: true,
  reorderPoint: true,
  inTransit: true,
  receiving: true,
  safetyStock: true,
  unitsSold: true,
};

export const legendColorMapping: Record<string, string> = {
  available: "#5295E0",
  reorderPoint: "#255FA0",
  inTransit: "#F19867",
  receiving: "#6B4DBA",
  safetyStock: "#7AC57B",
  unitsSold: "#5C5C5A",
};

export function generateDateRangeArray(
  startDate: string | Date,
  endDate: string | Date,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  const current = new Date(start);

  while (current <= end) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return dates;
}

export const generateData = (
  startDate: Date,
  endDate: Date,
  activeChecks: string[],
) => {
  const dates = generateDateRangeArray(startDate, endDate);

  return dates.map((date) => {
    const obj: any = {
      date: format(date, "d MMM"),
    };

    activeChecks.forEach((check) => {
      obj[check] = Math.floor(Math.random() * 1000);
    });

    return obj;
  });
};
const renderCustomizedBar = ({
  x,
  y,
  width,
  height,
  payload,
  dataKey,
  activeLabel,
  showForecast,
  index,
}: RenderProps) => {
  const isActive = payload.date === activeLabel;

  const lastDate = new Date(data[data.length - 1].date);
  const forecastDate = new Date(lastDate);
  forecastDate.setDate(lastDate.getDate() + index);

  const color = legendColorMapping[dataKey] || "#5C5C5A";

  return (
    <CustomBar
      x={x}
      y={y}
      width={width}
      height={height}
      fill={color}
      showForecast={showForecast}
      activeLabel={activeLabel}
      payload={payload}
      dataKey={dataKey}
    />
  );
};

export default renderCustomizedBar;
