import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Option, Select } from "@components/Select";
import { useEffect, useState } from "react";
import { ProductsService } from "@services/amazon/products/products.service";
import { Brand, ProductWithImageLink } from "@services/amazon/products/types";

export const BrandAsinSelect: React.FC = () => {
  const [asinOptions, setAsinOptions] = useState<Option[]>();
  const [brandOptions, setBrandOptions] = useState<Option[]>();
  const [asinPage, setAsinPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const {
    isAsinSelect,
    asinSelected,
    brandSelected,
    setAsinBrandSelect,
    setBrandSelected,
    setAsinSelected,
  } = useDashboardStore();

  useEffect(() => {
    const fetchAsinOptions = async () => {
      try {
        const productsService = new ProductsService();
        const [productAsinData, productBrandData] = await Promise.all([
          productsService.getOwnProducts({
            limit: 32 * asinPage,
          }),
          productsService.getOwnProductsBrand({
            limit: 32 * brandPage,
          }),
        ]);
        const asinOptions = productAsinData.map(
          (product: ProductWithImageLink) => ({
            id: product.asin,
            text: product.item_name,
            img: product.image_link,
            asin: product.asin,
          }),
        );
        const brandOptions = productBrandData.map((product: Brand) => ({
          id: product.name,
          text: product.asins.join(", "),
        }));
        setBrandOptions(brandOptions);
        setAsinOptions(asinOptions);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchAsinOptions();
  }, [isAsinSelect, asinPage, brandPage]);

  const handleChange = (value: string | string[]) => {
    if (isAsinSelect) {
      setAsinSelected(value as string[]);
    } else {
      setBrandSelected(value as string[]);
    }
  };

  const handleLoadMore = () => {
    if (isAsinSelect) {
      setAsinPage(asinPage + 1);
    } else {
      setBrandPage(brandPage + 1);
    }
  };

  const selectOptions = {
    value: isAsinSelect ? asinSelected : brandSelected,
    options: isAsinSelect ? asinOptions : brandOptions,
    onChange: handleChange,
    placeholder: isAsinSelect ? "Select ASIN" : "Select Brand",
    className: styles.select,
    isMultiSelect: true,
  };

  return (
    <div className={styles.container}>
      <div className={styles.switch__container}>
        <button
          className={classNames(styles.switch, {
            [styles.active]: !isAsinSelect,
          })}
          onClick={() => setAsinBrandSelect(false)}
        >
          Brand
        </button>
        <button
          className={classNames(styles.switch, {
            [styles.active]: isAsinSelect,
          })}
          onClick={() => setAsinBrandSelect(true)}
        >
          ASIN
        </button>
      </div>
      <Select {...selectOptions}>
        <button
          className={styles.reset}
          onClick={() => {
            setBrandSelected([]);
            setAsinSelected([]);
          }}
        >
          Reset
        </button>
      </Select>
    </div>
  );
};
