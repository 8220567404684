import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ExpandableTable } from "@components/ExpandebleTable";
import { columns, data } from "./mock";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import Search from "@assets/icons/search";

export const OrganicKeywordTable: React.FC = () => {
  const [selections, setSelections] = useState<any[]>([]);
  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  return (
    <div className={styles.container} id={styles.table}>
      <h3>Organic Keyword Performance</h3>
      <ExpandableTable
        columns={columns}
        data={data}
        withCustomScroll
        rowSelection={rowSelections}
        className={styles.table}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};
