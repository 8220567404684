import { SalesPerformanceApi } from "./sales-performance.api";
import { format } from "date-fns";

interface ChartDataPoint {
  date: string;
  [key: string]: number | string;
}

export class SalesPerformanceService {
  private api: SalesPerformanceApi;

  constructor() {
    this.api = new SalesPerformanceApi();
  }

  private async fetchMetricData(
    metric: string,
    startDate: string,
    endDate: string,
  ): Promise<number[]> {
    try {
      switch (metric) {
        case "adSpend":
          return await this.api.getAdSpend(startDate, endDate);
        case "totalSales":
          return await this.api.getTotalSales(startDate, endDate);
        case "adSales":
          return await this.api.getAdSales(startDate, endDate);
        case "adAcos":
          return await this.api.getTotalAcos(startDate, endDate);
        case "adRoas":
          return await this.api.getTotalRoas(startDate, endDate);
        case "adCpc":
          return await this.api.getTotalCpc(startDate, endDate);
        case "adClicks":
          return await this.api.getAdClicks(startDate, endDate);
        case "adImpressions":
          return await this.api.getAdImpressions(startDate, endDate);
        case "adCtr":
          return await this.api.getAdCtr(startDate, endDate);
        case "adCvr":
          return await this.api.getAdCvr(startDate, endDate);
        default:
          return [];
      }
    } catch (error) {
      console.error(`Error fetching ${metric}:`, error);
      return [];
    }
  }

  public async getChartData(
    metrics: string[],
    startDate: Date,
    endDate: Date,
  ): Promise<ChartDataPoint[]> {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    const metricsData = await Promise.all(
      metrics.map((metric) =>
        this.fetchMetricData(metric, formattedStartDate, formattedEndDate),
      ),
    );

    const dates = this.generateDateRange(startDate, endDate);

    return dates.map((date, index) => {
      const dataPoint: ChartDataPoint = { date };
      metrics.forEach((metric, mIndex) => {
        dataPoint[metric] = metricsData[mIndex][index] || 0;
      });
      return dataPoint;
    });
  }

  private generateDateRange(start: Date, end: Date): string[] {
    const dates: string[] = [];
    const current = new Date(start);

    while (current <= end) {
      dates.push(format(current, "MMM dd"));
      current.setDate(current.getDate() + 1);
    }

    return dates;
  }
}
