import { Checkbox, Button } from "antd";
import { useState, useEffect, ChangeEvent } from "react";
import styles from "./styles.module.scss";
import SearchIcon from "src/assets/icons/SearchIcon.svg";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import info from "@assets/icons/i.svg";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useMetricStore } from "src/store/metrict.state";
import { useDataBlockMetricStore } from "src/store/metric-block.state";
import {
  categoryMetricsMap,
  ITEMS_PER_PAGE,
  mockCategories,
  transformWidget,
} from "./mock";
import { useProductStore } from "src/store/overviewProduct.state";
import { SpiderService } from "@services/spider/spider.services";

interface ConfigModalProps {
  buttonWidth: number;
  closeModal: () => void;
  onApply: (selectedMetrics: IWidget[]) => void;
  storeType: "table" | "dataBlock";
}

interface IWidgetExtended extends IWidget {
  selected?: boolean;
}

export const ConfigModalProduct = ({
  closeModal,
  onApply,
  storeType,
}: ConfigModalProps) => {
  const { availableWidgets } = useDashboardStore((state) => ({
    availableWidgets: state.availableWidgets,
  }));

  const { selectedProduct } = useProductStore();
  const [posValue, setPosValue] = useState<string>("");
  const metricStore =
    storeType === "table" ? useMetricStore() : useDataBlockMetricStore();
  const { selectedMetric, setSelectedMetric } = metricStore;

  const processedWidgets: IWidget[] = availableWidgets.map(transformWidget);

  const [widgetList, setWidgetList] = useState<IWidgetExtended[]>(
    processedWidgets.map((widget) => ({
      ...widget,
      selected: selectedMetric.some((m) => m.id === widget.id),
    })),
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const processed = availableWidgets.map(transformWidget);
    setWidgetList(
      processed.map((widget) => ({
        ...widget,
        selected: selectedMetric.some((m) => m.id === widget.id),
      })),
    );
  }, [availableWidgets, selectedMetric]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCheckboxChange = (metricId: string) => {
    setWidgetList((prevWidgetList) => {
      const newList = [...prevWidgetList];
      const idx = newList.findIndex((m) => m.id === metricId);
      if (idx === -1) {
        return prevWidgetList;
      }
      const selectedCount = newList.filter((w) => w.selected).length;
      const isCurrentlySelected = newList[idx].selected;
      if (!isCurrentlySelected && selectedCount >= maxSelectedWidgets) {
        return prevWidgetList;
      }
      newList[idx] = {
        ...newList[idx],
        selected: !isCurrentlySelected,
      };
      const selectedMetrics = newList.filter((widget) => widget.selected);
      setSelectedMetric(selectedMetrics);

      return newList;
    });
  };

  const handleApply = () => {
    const selectedMetrics = widgetList.filter((widget) => widget.selected);
    if (selectedMetrics.length === maxSelectedWidgets) {
      setSelectedMetric(selectedMetrics);
      onApply(selectedMetrics);
      closeModal();
    }
  };

  const fetchPosValue = async () => {
    const asin =
      typeof selectedProduct === "string"
        ? selectedProduct
        : selectedProduct?.asin;
    try {
      const spider = new SpiderService();
      const spiderDots = await spider.getCroInfoPos(asin);
      const roundedValue = Math.round(spiderDots.total_pos).toString();
      setPosValue(roundedValue);
    } catch (error) {
      setPosValue("Error");
    }
  };

  const handleCategoryClick = (index: number) => {
    setSelectedCategoryIndex(index === selectedCategoryIndex ? null : index);
    setCurrentPage(1);
    if (mockCategories[index]?.id === 1) {
      fetchPosValue();
    }
  };

  useEffect(() => {
    setWidgetList((prevWidgetList) => {
      const processed = availableWidgets.map(transformWidget);
      const updatedList = processed.map((widget) => ({
        ...widget,
        selected: selectedMetric.some((m) => m.id === widget.id),
      }));

      if (!updatedList.some((widget) => widget.id === "pos")) {
        updatedList.push({
          id: "pos",
          name: "POS",
          value: "",
          originalValue: "",
          selected: selectedMetric.some((m) => m.id === "pos"),
        });
      }
      const updatedListUnique = updatedList.filter(
        (widget, index, self) =>
          index === self.findIndex((w) => w.id === widget.id),
      );
      return updatedListUnique;
    });
  }, [availableWidgets, selectedMetric]);

  const filteredMetrics = widgetList.filter((widget) => {
    const matchesSearch = widget.name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());
    if (!matchesSearch) return false;

    if (selectedCategoryIndex === null) {
      return true;
    } else {
      const selectedCategory = mockCategories[selectedCategoryIndex];
      const categoryId = selectedCategory.id;

      if (categoryId in categoryMetricsMap) {
        const included = categoryMetricsMap[categoryId].includes(widget.name);
        const showPOS = widget.id === "pos" && categoryId === 4;
        return included || showPOS;
      } else {
        return true;
      }
    }
  });

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentMetrics = filteredMetrics.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredMetrics.length / ITEMS_PER_PAGE);

  const maxSelectedWidgets = 7;
  const selectedCount = widgetList.filter((w) => w.selected).length;
  const disableApply = selectedCount !== maxSelectedWidgets;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.metrics}>
          <div className={styles.metricsHeader}>
            <p className={styles.title}>Add Metrics</p>
            <div className={styles.searchContainer}>
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <img
                src={SearchIcon}
                alt="Search"
                className={styles.searchIcon}
              />
            </div>
          </div>
          <h5 className={styles.metricsAll}>All metrics</h5>
          {selectedCategoryIndex === null ? (
            <div className={styles.categoryListWrapper}>
              {mockCategories.map((category, index) => (
                <div
                  className={styles.categoryItem}
                  key={index}
                  onClick={() => handleCategoryClick(index)}
                >
                  <span className={styles.categoryText}>{category.name}</span>
                  <span className={styles.categoryArrow}>›</span>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.metricListWrapper}>
              <div
                className={styles.backButton}
                onClick={() => setSelectedCategoryIndex(null)}
              >
                ← Back to Categories
              </div>
              <div className={styles.metricItemsContainer}>
                {currentMetrics.map((metric) => (
                  <div key={metric.id} className={styles.metricItem}>
                    <Checkbox
                      checked={metric.selected}
                      className={styles.checkboxMargin}
                      disabled={
                        !metric.selected && selectedCount >= maxSelectedWidgets
                      }
                      onChange={() => handleCheckboxChange(metric.id)}
                    />

                    <span className={styles.metricName}>{metric.name}</span>
                    <img className={styles.info} src={info} alt="info" />
                  </div>
                ))}
              </div>

              <div className={styles.paginationControls}>
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1,
                ).map((page) => (
                  <button
                    key={page}
                    onClick={() => {
                      setCurrentPage(page);
                    }}
                    className={`${styles.paginationButton} ${currentPage === page ? styles.activePage : ""}`}
                  >
                    {page}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Button
        type="primary"
        shape="round"
        className={styles.applyBtn}
        onClick={handleApply}
        disabled={disableApply}
      >
        Apply
      </Button>
    </div>
  );
};
