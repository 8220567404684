import { create } from "zustand";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { initializeCalendarData } from "src/utils/initialCalendarData";
import { WidgetMetricKey } from "@services/math/math.service";
import {
  WIDGET_METRIC_GROUPS,
  WIDGET_METRIC_KEYS,
} from "@services/math/math.const";

export interface IDashboardState {
  widgets: WidgetMetricKey[];
  availableWidgets: WidgetMetricKey[];
  modalWidgets: IWidget[];
  selectedWidgets: WidgetMetricKey[];
  selectedWidgetsCOO: WidgetMetricKey[];
  selectedWidgetsSales: WidgetMetricKey[];
  selectedWidgetsMarket: WidgetMetricKey[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  isAsinSelect: boolean;
  setAsinBrandSelect: (value: boolean) => void;
  asinSelected: string[];
  setAsinSelected: (value: string[]) => void;
  brandSelected: string[];
  setBrandSelected: (value: string[]) => void;
  compareType: "raw" | "percent";
  setCompareType: (type: "raw" | "percent") => void;
  setWidgets: (widgets: WidgetMetricKey[]) => void;
  setAvailableWidgets: (widgets: WidgetMetricKey[]) => void;
  setModalWidgets: (widgets: IWidget[]) => void;
  setSelectedWidgets: (widgets: WidgetMetricKey[]) => void;
  setSelectedWidgetsCOO: (widgets: WidgetMetricKey[]) => void;
  setSelectedWidgetsSales: (widgets: WidgetMetricKey[]) => void;
  setSelectedWidgetsMarket: (widgets: WidgetMetricKey[]) => void;
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => void;
  reset: () => void;
}

const { today, monday } = initializeCalendarData();

export const defaultWidgets: WidgetMetricKey[] = [
  "totalSales",
  "adClicks",
  "adSales",
  "adSpend",
  "adAcos",
  "adRoas",
  "adCpc",
  "adCvr",
  "adCtr",
  "adUnitsSold",
];

export const COOWidgets: WidgetMetricKey[] = WIDGET_METRIC_GROUPS.filter(
  (group) => group.title === "COO",
)[0].keys;

export const MarketWidgets: WidgetMetricKey[] = WIDGET_METRIC_GROUPS.filter(
  (group) => group.title === "Market",
)[0].keys;

export const SalesWidgets: WidgetMetricKey[] = [
  "totalSales",
  "totalSpend",
  "contribution",
  "totalUnitsSold",
];

export const useDashboardStore = create<IDashboardState>((set) => ({
  widgets: [],
  availableWidgets: [...WIDGET_METRIC_KEYS],
  modalWidgets: [],
  selectedWidgets: defaultWidgets,
  selectedWidgetsCOO: COOWidgets,
  selectedWidgetsMarket: MarketWidgets,
  selectedWidgetsSales: SalesWidgets,
  isAsinSelect: true,
  setAsinBrandSelect: (value: boolean) => set({ isAsinSelect: value }),
  asinSelected: [],
  setAsinSelected: (value: string[]) => set({ asinSelected: value }),
  brandSelected: [],
  setBrandSelected: (value: string[]) => set({ brandSelected: value }),
  dateRange: {
    endDate: today,
    startDate: monday,
  },
  compareType: "raw",
  setCompareType: (type: "raw" | "percent") => set({ compareType: type }),
  setWidgets: (widgets: WidgetMetricKey[]) => set({ widgets }),
  setAvailableWidgets: (availableWidgets: WidgetMetricKey[]) => {
    if (!Array.isArray(availableWidgets)) {
      return;
    }
    set({ availableWidgets });
  },
  setModalWidgets: (modalWidgets: IWidget[]) => set({ modalWidgets }),
  setSelectedWidgets: (selectedWidgets: WidgetMetricKey[]) =>
    set({ selectedWidgets }),
  setSelectedWidgetsCOO: (selectedWidgetsCOO: WidgetMetricKey[]) =>
    set({ selectedWidgetsCOO }),
  setSelectedWidgetsMarket: (selectedWidgetsMarket: WidgetMetricKey[]) =>
    set({ selectedWidgetsMarket }),
  setSelectedWidgetsSales: (selectedWidgetsSales: WidgetMetricKey[]) =>
    set({ selectedWidgetsSales }),
  setDateRange: (range: { startDate: Date; endDate: Date }) =>
    set({ dateRange: range }),
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => {
    set({ widgets, availableWidgets });
  },
  reset: () =>
    set({
      widgets: [],
      availableWidgets: [],
      modalWidgets: [],
      dateRange: { startDate: new Date(), endDate: new Date() },
    }),
}));
