import { useEffect, useState } from "react";
import { Button, Card } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styles from "./styles.module.scss";
import renderCustomizedBar, {
  colorMapping,
  data as dataMock,
  generateData,
  legendColorMapping,
  nameChoosedMapping,
} from "./utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import PoSalesTable from "../PoSalesTable";
import { SettingsModal } from "./modal";
import Setting from "@assets/icons/setting";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import classNames from "classnames";

const InventoryPerformance = () => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [dataPoints, setDataPoints] = useState(nameChoosedMapping);
  const [showForecast, setShowForecast] = useState<boolean>(false);
  const [isPoModal, setIsPoModal] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [data, setData] = useState(dataMock);
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));

  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) return;

    const activeChecks = Object.entries(dataPoints)
      .filter(([_, isChecked]) => isChecked)
      .map(([key]) => key);

    const datesCount = Math.abs(
      new Date(dateRange.endDate).getDate() -
        new Date(dateRange.startDate).getDate(),
    );

    let newData;

    if (showForecast) {
      const startDate = new Date(dateRange.endDate);
      startDate.setDate(startDate.getDate() + 1);

      const endDate = new Date(dateRange.endDate);
      endDate.setDate(endDate.getDate() + datesCount + 1);

      newData = generateData(startDate, endDate, activeChecks);
    } else {
      newData = generateData(
        dateRange.startDate,
        dateRange.endDate,
        activeChecks,
      );
    }

    setData(newData);
  }, [showForecast, dateRange, dataPoints]);

  return (
    <Card className={styles.card}>
      <div className={styles.headerBetween}>
        <h1 className={styles.title}>
          {isPoModal
            ? "PO Sales, Margin & ROI Dynamics"
            : "Inventory Performance"}
        </h1>
        <Button
          type="text"
          icon={<Setting />}
          onClick={() => setIsOpenModal(true)}
        />
      </div>
      <div className={styles.legendContainer}>
        {Object.keys(dataPoints).map(
          (key) =>
            dataPoints[key] && (
              <div className={styles.legendItem} key={key}>
                <span
                  className={styles.colorDot}
                  style={{
                    backgroundColor: legendColorMapping[key] || "#5C5C5A",
                  }}
                ></span>
                <span className={styles.legendText}>
                  {WIDGET_METRICS_CONFIG[key].label}
                </span>
              </div>
            ),
        )}
      </div>
      {!isPoModal ? (
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
              barGap={2}
              barSize={39}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setActiveLabel(state.activeLabel as string);
                } else {
                  setActiveLabel(null);
                }
              }}
              onMouseLeave={() => setActiveLabel(null)}
            >
              <CartesianGrid stroke="#1F1F20" vertical={false} />
              <XAxis
                dataKey="date"
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
              />
              <YAxis
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
                ticks={[2500, 2000, 1500, 1000, 500, 0]}
                domain={[0, 2500]}
                tickFormatter={(value) => value.toLocaleString("en-US")}
              />
              <Tooltip
                content={({ active, payload, label }) => (
                  <CustomTooltip
                    active={active}
                    payload={payload}
                    colorMetric={legendColorMapping}
                    label={label}
                  />
                )}
                cursor={{
                  fill: "transparent",
                }}
              />

              {Object.entries(dataPoints).map(([key, isEnabled]) => {
                if (isEnabled && key !== "safetyStock") {
                  return (
                    <Bar
                      key={key}
                      dataKey={key}
                      stackId={key === "noUnitsSold" ? "b" : "a"}
                      name={WIDGET_METRICS_CONFIG[key].label}
                      shape={(props) =>
                        renderCustomizedBar({
                          ...props,
                          activeLabel,
                          showForecast,
                          colorMapping,
                        })
                      }
                    />
                  );
                }
              })}
            </BarChart>
          </ResponsiveContainer>
          {dataPoints.safetyStock && (
            <div
              className={classNames(
                styles.borderOverlay,
                showForecast && styles.borderOverlayForecast,
              )}
            ></div>
          )}
        </div>
      ) : (
        <PoSalesTable />
      )}
      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        setDataPoints={setDataPoints}
        dataPoints={dataPoints}
        showForecast={showForecast}
        setShowForecast={setShowForecast}
        isPoModal={isPoModal}
        setIsPoModal={setIsPoModal}
      />
    </Card>
  );
};

export default InventoryPerformance;
