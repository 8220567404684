import { useEffect, useMemo, useState } from "react";
import { TabsTypeList } from "@pages/AdsAnalitics/components/SanKey/utils";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { Select, Option } from "@components/Select";
import { ExpandableTable } from "@components/ExpandebleTable";
import { useKeywordStore } from "src/store/keyword.state";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { CanvasApi } from "@services/canvas/canvas.api";
import styles from "./styles.module.scss";
import { formatData } from "../utils";
import {
  fetcherMap,
  getColumns,
  isTotalFirst,
  level1Map,
  level2Map,
  removeTotalChildren,
  toIsoDate,
  updateRecordChildren,
} from "./utils";
import { INestedData, TableItem } from "../utils.types";

interface CampaignsTableProps {
  hideAsin?: boolean;
}

export const CampaignsTable = ({ hideAsin }: CampaignsTableProps) => {
  const { keyword, setKeywords } = useKeywordStore();
  const { dateRange } = useDashboardStore();
  const [isLoading, setIsLoading] = useState(false);
  const canvasApi = useMemo(() => new CanvasApi(), []);

  const [temporaryData, setTemporaryData] = useState<TableItem[] | undefined>();

  const [tableData, setTableData] = useState<any[]>([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");

  const [tooltipTabsTitle, setTooltipTabsTitle] = useState("");
  const [tooltipKeysTitle, setTooltipKeysTitle] = useState("");

  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();

  const { startDate, endDate } = dateRange;
  const from = toIsoDate(startDate);
  const to = toIsoDate(endDate);

  /** Fetches products for the ASIN dropdown. */
  useEffect(() => {
    const fetchAsinOptions = async () => {
      try {
        const productsService = new ProductsService();
        const productData = await productsService.getOwnProducts();
        const options = productData.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));
        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchAsinOptions();
  }, [asin]);

  /**
   * Fetch top-level performance data if the selected tab is "Performance" or "Ad Type".
   */
  useEffect(() => {
    const fetchPerformanceData = async () => {
      setIsLoading(true);
      try {
        const fetchMethod = fetcherMap[selectedTab];
        if (!fetchMethod) return;

        const fetchedData = await fetchMethod(from, to);

        const shapedData: INestedData[] = fetchedData.map((item, index) => ({
          ...item,
          key: index,
          level: 1,
          hasFetchedChildren: false,
          children: [],
        }));

        setTemporaryData(shapedData);
      } catch (error) {
        console.error("Error fetching performance data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPerformanceData();
  }, [selectedTab, dateRange, canvasApi]);

  /**
   * Handles row expansion.
   * On expand, we fetch children data (campaigns / search terms / targets, etc.).
   * On collapse, we remove them from `expandedRowKeys` and optionally clear children.
   */
  const handleExpand = async (expanded: boolean, record: any) => {
    if (!expanded) {
      setExpandedRowKeys((prev) => prev.filter((k) => k !== record.key));
      setTemporaryData(
        (prev) => prev && updateRecordChildren(prev, record.key, []),
      );
      return;
    }

    setExpandedRowKeys((prev) => [...prev, record.key]);

    try {
      setIsLoading(true);
      const getData =
        record.level == 1 ? level1Map[selectedTab] : level2Map[selectedTab];
      if (!getData) return;

      const childrenData = await getData(from, to, record);

      const shapedChildren = childrenData.map((child: any, index: number) => ({
        ...child,
        key: `${record.key}-${index}`,
        level: record.level + 1,
        hasFetchedChildren: false,
        performance: child.targeting_text || child.campaign_name,
        campaign_name: child.search_term,
        ad_type: child.search_term || child.campaign,
        target: child.campaign_name,
        match_type: child.target || child.campaign_name,
        match_type_key: child.match_type,
        performanceKey: child.performance,
        ad_typeKey: record.ad_type,
        funnelKey: record.funnel,
        children: [],
      }));

      setTemporaryData((prev) => {
        return prev && updateRecordChildren(prev, record.key, shapedChildren);
      });
    } catch (error) {
      console.error("Error fetching children data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Every time performanceData or the selectedTab changes,
   * recalculate `tableData` so that the table sees the updated structure.
   */
  useEffect(() => {
    const data = isTotalFirst(temporaryData)
      ? temporaryData?.reverse()
      : temporaryData;

    const finalData = formatData({
      data: removeTotalChildren(data) || [],
      openTooltip: setTooltipTabsTitle,
      openedTooltip: tooltipTabsTitle,
      keyTooltip: tooltipKeysTitle,
      setKeyTooltip: setTooltipKeysTitle,
      keyword,
      setKeywords,
      withKeywords: selectedTab === "Campaigns",
    });

    setTableData(finalData);
  }, [temporaryData, tooltipTabsTitle, tooltipKeysTitle, keyword, setKeywords]);

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [selectedTab]);

  /**
   * Handle row selection changes (checkbox in first column).
   */
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {/* ASIN dropdown (hidden if hideAsin=true) */}
      {!hideAsin && (
        <div className={styles.inputBox}>
          <h2 className={styles.asinText}>ASIN</h2>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => setAsin(value)}
            className={styles.asin}
          />
        </div>
      )}

      <TabsTypeList
        isFunnelChart
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <div className={styles.table}>
        <ExpandableTable
          columns={getColumns(selectedTab)}
          data={tableData}
          rowSelection={rowSelection}
          scroll={{ x: 1000, y: 300 }}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            rowExpandable: () => false,
          }}
          adSalesTab={selectedTab}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
