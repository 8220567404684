import styles from "../Auth.module.scss";
import { useCallback, useState } from "react";
import { AuthServices } from "@services/auth.services";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import { IGetRedirectUrlResponse } from "src/@types/auth.types";
import { AuthProvider } from "src/constants/auth.constants";
import { AuthNavigation } from "@pages/Auth/AuthNavigation";
import { LS } from "src/constants/localStorage.constants";
import { Button } from "@components/ui-kit/Button/Button";
import { useTranslation } from "react-i18next";
import AuthInput from "@components/AuthInput";
import AuthButton from "@components/AuthButton";
import Google from "@assets/icons/google";
import SignupService from "@services/signup.service";

export const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
  });

  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");

  const handleInputChange = (field) => (e) => {
    setUserData({ ...userData, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const onSignUp = useCallback((provider: AuthProvider) => {
    return () => {
      setLoading(true);
      AuthServices.getRedirectUrl(provider)
        .then(({ url }: IGetRedirectUrlResponse) => {
          localStorage.setItem(LS.AuthProvider, provider);
          window.location.replace(url);
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, []);

  const handleLocalSignUp = async () => {
    setLoading(true);
    setErrors({});
    setGeneralError("");

    try {
      await SignupService.signup({
        first_name: userData.firstName,
        last_name: userData.lastName,
        email: userData.email,
        password: userData.password,
        re_password: userData.rePassword,
      });

      navigate("/auth/successful-registration");
    } catch (error) {
      setErrors({
        firstName: error.response.data.first_name?.join(" "),
        lastName: error.response.data.last_name?.join(" "),
        email: error.response.data.email?.join(" "),
        password: error.response.data.password?.join(" "),
        rePassword: error.response.data.re_password?.join(" "),
      });

      if (
        !error.response.data.first_name &&
        !error.response.data.last_name &&
        !error.response.data.email &&
        !error.response.data.password &&
        !error.response.data.re_password
      ) {
        setGeneralError(
          error.response.data.detail ||
            "An unexpected error occurred. Please try again.",
        );
      }

      setLoading(false);
    }
  };

  const inputs = [
    { title: "first name", field: "firstName", type: "text" },
    { title: "last name", field: "lastName", type: "text" },
    { title: "e-mail", field: "email", type: "email" },
    { title: "password", field: "password", type: "password", password: true },
    {
      title: "confirm password",
      field: "rePassword",
      type: "password",
      password: true,
    },
  ];

  return (
    <div className={styles.authContainer}>
      <AuthNavigation />
      <div className={styles.inputList}>
        {inputs.map((input, index) => (
          <div key={index} className={styles.inputItem}>
            <AuthInput
              title={input.title}
              type={input.type}
              value={userData[input.field]}
              onChange={handleInputChange(input.field)}
              password={input.password || false}
            />
            {errors[input.field] && (
              <div className={styles.error}>{errors[input.field]}</div>
            )}
          </div>
        ))}
      </div>
      {generalError && <div className={styles.error}>{generalError}</div>}
      <AuthButton text="Sign Up" onClick={handleLocalSignUp} />

      <div className={styles.authBtnsBox}>
        <Button
          variant="green"
          onClick={onSignUp(AuthProvider.Google)}
          loading={loading}
        >
          <p className={styles.authBtn}>
            {t("action.signup_with_google")} <Google />
          </p>
        </Button>
      </div>

      <div className={styles.authPagePrivacy}>
        <p>
          By signing up, I acknowledge that I have read and agree to the
          <a
            href="https://www.nyle.ai/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>{" "}
          and
          <a
            href="https://www.nyle.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy.
          </a>
        </p>
        <p>
          Are you already registered?&nbsp;
          <Link to={`${ROUTES.AUTH}/${ROUTES.AUTH_LOGIN}`}>Log in</Link>
        </p>
      </div>
    </div>
  );
};
