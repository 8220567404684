import CameraIcon from "@assets/icons/camera";
import styles from "./styles.module.scss";
import amazonIcon from "@assets/img/amazon.png";
import { ROUTES } from "src/router/routes";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import WelcomeArrow from "@assets/icons/welcomeArrow";
import RightArrow from "@assets/icons/sliderBtn";
import classNames from "classnames";
import { images } from "@components/WelcomeSection/mock";
import { useTranslation } from "react-i18next";
import { useMaintenance } from "src/store/maintenance.state";

function ConnectAmazon() {
  const [showContent, setShowContent] = useState(false);
  const toggleContent = () => setShowContent((prev) => !prev);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { maintenance } = useMaintenance();

  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);

  const imagesToShow = 4;
  const maxIndex = images.length - imagesToShow;
  const moveLeft = () => {
    setFirstVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const visibleImages = images.slice(
    firstVisibleIndex,
    firstVisibleIndex + imagesToShow,
  );
  const moveRight = () => {
    setFirstVisibleIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };
  const onSkipClick = useCallback(() => {
    navigate(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/generator`);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <h1>Connect to Amazon</h1>
            <div className={styles.videoBtn}>
              {maintenance && (
                <button
                  className={styles.buttonWrapper}
                  onClick={toggleContent}
                >
                  {showContent ? (
                    <p>
                      Hide <WelcomeArrow />
                    </p>
                  ) : (
                    <p>
                      Learn <CameraIcon />
                    </p>
                  )}
                </button>
              )}
            </div>
          </div>
          {showContent && (
            <div
              className={classNames(styles.additionalContent, {
                [styles.withLeft]: firstVisibleIndex,
                [styles.withRight]: !(firstVisibleIndex < maxIndex),
              })}
            >
              <div className={styles.cardsWrapper}>
                {visibleImages.map((image, index) => (
                  <div
                    key={`Card ${firstVisibleIndex + index + 1}`}
                    className={styles.card}
                  >
                    <img
                      src={image.src}
                      alt={`Card ${firstVisibleIndex + index + 1}`}
                    />
                  </div>
                ))}
              </div>
              {firstVisibleIndex > 0 && (
                <button className={styles.leftArrow} onClick={moveLeft}>
                  <RightArrow />
                </button>
              )}
              {firstVisibleIndex < maxIndex && (
                <button className={styles.rightArrow} onClick={moveRight}>
                  <RightArrow />
                </button>
              )}
            </div>
          )}
          <div className={styles.boxes}>
            <div className={styles.box}>
              <div className={styles.box__inner}>
                <h2>Connect Your Seller Accounts</h2>
                <p>
                  Once connected, you can automate your product review requests,
                  track your financial data, monitor your products and detect
                  hijackers.
                </p>
                <Link to={`/${ROUTES.AMAZON_ONBOARDING}`} className="button">
                  Connect Your Seller Account <AuthButtonArrow />
                </Link>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.box__inner}>
                <h2>Connect Your Amazon Ads Account</h2>
                <p>
                  Once connected, you can track your Ad spend, examine Ad
                  campaigns performance and automate your Amazon Ad campaigns.{" "}
                </p>
                <Link to={`/${ROUTES.REDIRECT_TO_AMAZON}`} className="button">
                  Connect Your Ads Accounts <AuthButtonArrow />
                </Link>
              </div>
            </div>
          </div>
          <button
            className={classNames("amazon-onboarding__skip", styles.skip)}
            onClick={onSkipClick}
          >
            {t("action.skip")}
          </button>
        </div>
        <img src={amazonIcon} alt="amazon" />
      </div>
    </div>
  );
}

export default ConnectAmazon;
