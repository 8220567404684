import { useState } from "react";
import styles from "./styles.module.scss";
import { TabsTypeList, TypeTab } from "./Tabs";
import { HeatMap } from "./Charts/HeatMap";
import { HorizontalChart } from "@components/HorizontalChart";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { WhatIfTab } from "../Tables/WhatIfTable/Tabs";
import { campaignsData, targetingData } from "./Charts/Sankey/mock";
import { DotPlot } from "./Charts/DotPlot";

const getSankeyData = (tab: WhatIfTab) => {
  switch (tab) {
    case "Campaigns":
      return campaignsData;
    case "Targeting":
      return targetingData;
    default:
      return campaignsData;
  }
};

export const WhatIfCharts = () => {
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Heatmap");
  const { sankeyTab } = useAdsAnaliticsStore();

  return (
    <div className={styles.container}>
      <TabsTypeList selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === "Heatmap" && (
        <div className={styles.chart__container}>
          <HeatMap />
        </div>
      )}

      {selectedTab === "Sankey" && (
        <div className={styles.chart__container}>
          <h2>{sankeyTab}</h2>
          <HorizontalChart
            style={{ width: "109%", left: "-5.7%" }}
            data={getSankeyData(sankeyTab)}
          />
        </div>
      )}
      {selectedTab === "Ad Campaign Dotplot" && (
        <div className={styles.chart__container}>
          <DotPlot />
        </div>
      )}
    </div>
  );
};
